import { Directive, ElementRef, HostListener, Input } from '@angular/core';

/**
 * Для поддержки Safari
 */
declare const DragEvent;
declare const ClipboardEvent;

/**
 * Декоратор директива и метаданные
 *
 * @Annotation
 * @publicApi
 */
@Directive({
  selector: '[onlyNumber]'
})

export class OnlyNumberDirective {

  /**
   * value: regexStr
   * @type {string}
   * переменная содержащая регулярное выражение, только цифры от 0 до 9
   */
  regexStr: string = '^[0-9]*$';

  /**
   * @Input()
   * Value: входное свойство onlyNumber
   * @type {boolean} привязка свойств дочернего и родительского компонента
   */
  @Input() onlyNumber: boolean;

  /**
   * @Input()
   * Value: входное свойство maxValue
   * @type {number} привязка свойств дочернего и родительского компонента
   */
  @Input() maxValue: number;

  /**
   * @Input()
   * Value: входное свойство floatSize
   * @type {number} привязка свойств дочернего и родительского компонента
   */
  @Input() floatSize: number;

  /**
   * Constructor
   * @param {ElementRef} el
   */
  constructor(private el: ElementRef) {
  }

  /**
   * @HostListener()
   * Декоратор @HostListener позволяет связать события DOM и методы директивы,
   * в декоратор передается название события, по которому будет вызываться метод
   */
  @HostListener('keydown', ['$event'])
  /**
   * Метод: обработчик событий нажатия клавиш
   * @param: {Event} event
   */
  onKeyDown(event: Event): void {
    const e: KeyboardEvent = <KeyboardEvent>event;
    if ([46, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 86 && e.ctrlKey === true) ||
      (e.keyCode === 88 && e.ctrlKey === true) ||
      (e.keyCode === 65 && e.metaKey === true) ||
      (e.keyCode === 67 && e.metaKey === true) ||
      (e.keyCode === 86 && e.metaKey === true) ||
      (e.keyCode === 88 && e.metaKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    }

    if (this.floatSize) {
      this.regexStr = '^[0-9]+([\\.\\,][0-9]{0,' + this.floatSize + '})?$';
    }

    let keyCode: number = e.keyCode || e.which;
    if (keyCode >= 96 && keyCode <= 105) {
      keyCode -= 48;
    }

    let ch: string = String.fromCharCode(keyCode);
    const target: EventTarget = e.target;
    const regEx: RegExp = new RegExp(this.regexStr);
    let value: string = (<HTMLInputElement>target).value;

    if (e.key === '.') {
      ch = '.';
    }

    if (e.key === ',') {
      ch = ',';
    }

    if (e.keyCode !== 8) {
      value = (value.slice(0, (<HTMLInputElement>target).selectionStart) + ch + value.slice((<HTMLInputElement>target).selectionEnd, value.length));
    } else {
      value = (value.slice(0, (<HTMLInputElement>target).selectionStart - 1) + value.slice((<HTMLInputElement>target).selectionEnd, value.length));
    }

    if (!this.floatSize) {
      if (this.onlyNumber) {
        if (regEx.test(ch) || e.keyCode === 8) {
          if (this.maxValue && parseInt(value, 10) && parseInt(value, 10) > this.maxValue) {
            e.preventDefault();
          }
          return;
        } else {
          e.preventDefault();
        }
      }
    } else {
      value = value.replace(/\\,/g, '.');
      if ((!regEx.test(value) || (parseFloat(value) && parseFloat(value) > this.maxValue)) && (value.length !== 0)) {
        e.preventDefault();
      }
    }
  }

  /**
   * @HostListener()
   * Декоратор @HostListener позволяет связать события DOM и методы директивы,
   * в декоратор передается название события, по которому будет вызываться метод
   */
  @HostListener('paste', ['$event'])
  /**
   * Метод: обработчик событий вставки в поле ввода (CTRL + V)
   * @param: {ClipboardEvent} event
   */
  onPaste(event: ClipboardEvent): void {
    if (this.onlyNumber) {
      event.preventDefault();
      const pastedInput: string = event.clipboardData
        .getData('text/plain')
        .replace(/\D/g, '');
      if (this.maxValue && parseInt(this.el.nativeElement.value + pastedInput, 10) > this.maxValue) {
        return;
      } else {
        document.execCommand('insertText', false, pastedInput);
      }
    }
  }

  /**
   * @HostListener()
   * Декоратор @HostListener позволяет связать события DOM и методы директивы,
   * в декоратор передается название события, по которому будет вызываться метод
   */
  @HostListener('drop', ['$event'])
  /**
   * Метод: обработчик события перетаскивания(бросания) элемента
   * @param: {DragEvent} event
   */
  onDrop(event: DragEvent): void {
    if (this.onlyNumber) {
      event.preventDefault();
      const textData: string = event.dataTransfer
        .getData('text').replace(/\D/g, '');
      this.el.nativeElement.focus();
      if (this.maxValue && parseInt(this.el.nativeElement.value + textData, 10) > this.maxValue) {
        return;
      } else {
        document.execCommand('insertText', false, textData);
      }
    }
  }
}

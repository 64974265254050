import {Component, OnDestroy, OnInit} from '@angular/core';
import {BasketSidebarService} from '@app/_core/components/basket-sidebar/services/basket-sidebar.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {LoaderService} from '@app/content/components/loader/loader.service';
import {BasketService} from '@app/content/containers/basket/services/basket.service';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {groupTrash} from '@app/_core/utils/group-trash.utils';

@Component({
    selector: 'app-basket-sidebar',
    templateUrl: './basket-sidebar.component.html',
    styleUrls: ['./basket-sidebar.component.scss']
})
export class BasketSidebarComponent implements OnInit, OnDestroy {
    public sidebarToggled$: Observable<boolean> = this.basketSidebarService.sidebarToggled$();
    public trashItems$: Observable<any[]> = this.basketSidebarService.getTrash();
    public ticketsCount$: Observable<number> = this.basketSidebarService.getTrashItemsCount();

    constructor(private basketSidebarService: BasketSidebarService,
                private loaderService: LoaderService,
                private readonly basketService: BasketService,
                private readonly router: Router,
    ) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.basketSidebarService.closeSidebar();
    }

    public toggleSidebar(): void {
        this.basketSidebarService.toggleSidebar();
    }

    public getCurrentWindowWidth() {
        return window.innerWidth;
    }

    public handleChangeCategory(): (data) => void {
        return (data) => {
            this.loaderService.open({min: environment.preloaderDelay});
            this.basketSidebarService.changeTicketCategory(data)
                .pipe(take(1))
                .subscribe(() => this.loaderService.close());
        };
    }

    public handleSeatUnreserve(): (data) => void {
        return (data) => {
            if (this.router.url.includes(data.locationSchemeSeatsOutgroupUuid)) {
                this.basketSidebarService.deleteItemInSector(data);
            } else {
                this.loaderService.open({min: environment.preloaderDelay});
                this.basketSidebarService.removeItem(data)
                    .pipe(take(1))
                    .subscribe((reservedSeats) => {
                        this.basketSidebarService.setTrash(groupTrash(reservedSeats));
                        this.loaderService.close();
                    });
            }
        };
    }


    public createOrder() {
        const windowObj: any = window;
        let clientId;
        try {
            const getAll: any = windowObj.ga ? windowObj.ga.getAll() : null;
            clientId = getAll && getAll[0] ? getAll[0].get('clientId') : null;
        } catch (e) {

        }
        this.loaderService.open({min: environment.preloaderDelay});
        this.basketService.createOrder()
            .then(async () => {
                await this.router.navigate(['/basket', this.basketService.getCreatedOrder().uuid], {queryParamsHandling: 'merge'});
                this.basketSidebarService.resetBasketWidget();
            })
            .finally(() => this.loaderService.close())
            .catch(() => {
            });
    }

}

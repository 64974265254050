import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@app/content/components/loader/loader.service';

import { Subscription } from 'rxjs';

import { ModalService } from '@app/shared/modal-service/modal.service';
import { ApiService } from '@app/shared/api/api.service';

import { ScaleElementAnimation } from '@app/_core/animation/animations';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-vip-application-modal',
  templateUrl: './vip-application-modal.component.html',
  styleUrls: ['./vip-application-modal.component.scss'],
  animations: [ScaleElementAnimation]
})
export class VipApplicationModalComponent implements OnInit {

  subscriptions: Subscription[] = [];

  data: any = {};

  constructor(
    private modalService: ModalService,
    private apiService: ApiService,
    private loaderService: LoaderService,
  ) {
  }

  ngOnInit() {
    const subscription: Subscription = this.modalService.getData()
      .subscribe((data: any) => {
        if (data) {
          this.data = data;
        }
      });
    this.subscriptions.push(subscription);
  }

  close(status: boolean): void {
    if (!status) {
      this.modalService
        .setResponse({...this.data, status: status})
        .close();
    } else {
      this.loaderService.open({min: environment.preloaderDelay});
      this.apiService.sendVipRequest(this.data)
        .then(() => {
          this.modalService
            .setResponse({...this.data, status: status})
            .close();
          this.loaderService.close();
        })
        .catch(() => {
          this.loaderService.close();
        });
    }

  }
}

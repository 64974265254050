import {Injectable} from "@angular/core";
import {ApiService} from "@app/shared/api/api.service";
import {IRequestObject} from "@app/models/shared/request-object.interface";
import {CreatedOrderInfo, OrderMetaInfo} from "@app/content/containers/basket/interfaces/created-order.interface";

@Injectable({
    providedIn: 'root'
})
export class BasketService {
    private createdOrder!: OrderMetaInfo;
    private createdOrderInfo!: CreatedOrderInfo;
    constructor(private api: ApiService) {
    }

    public async createOrder(): Promise<void> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order/new/from-reserve`
        };
        this.createdOrder = await this.api.post(requestObject, {}, false, false, false, true);
    }

    public async getOrderInfoByOrderId(orderId: string): Promise<void> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order/${orderId}/info`
        };
        this.createdOrderInfo = await this.api.get(requestObject);
    }

    public async getBonusesByOrderId(): Promise<number> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/bonus/balance`
        };
        return this.api.get(requestObject);
    }

    public async getOrderPayStatus(orderId: number): Promise<number> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order/payment-check?Order_ID=${orderId}`
        };
        return this.api.get(requestObject);
    }

    public getPayForm(orderId: string): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order/${orderId}/pay-form`
        };

        return this.api.get(requestObject, false, true);
    }

    public async payPartiallyWithBonuses(orderId: string, model: {amount: number}): Promise<CreatedOrderInfo> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order/${orderId}/bonuses`
        };
        return this.api.put(requestObject, model);
    }

    public async usePromocode(orderId: string, model: {promocode: string}): Promise<CreatedOrderInfo> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order/${orderId}/promocode`
        };
        return this.api.put(requestObject, model);
    }
    public getCreatedOrder(): OrderMetaInfo {
        return this.createdOrder;
    }
    public getCreatedOrderInfo(): CreatedOrderInfo {
        return this.createdOrderInfo;
    }
}

import {ReservedSeat} from '@app/content/containers/places/scheme.types';

export function groupTrash(trash: ReservedSeat[]): any {
    const result = {
        metadata: {
            sum: 0,
            count: 0
        },
        data: []
    };

    trash.forEach((ticket: ReservedSeat) => {
        const indexOfTicket = result.data
            .map((item) => item.game.eventSessionUuid)
            .indexOf(ticket.eventSessionUuid);

        if (indexOfTicket < 0) {
            const date: Date = new Date(ticket.eventSessionDateBegin);
            const hours: string = date.getHours() <= 9 ? '0' + String(date.getHours())  : String(date.getHours());
            const minutes: string = date.getMinutes() <= 9 ? '0' + String(date.getMinutes())  : String(date.getMinutes());
            result.data.push({
                game: {
                    eventSessionUuid: ticket.eventSessionUuid,
                    calendarName: ticket.eventCaption,
                    calendarDay: date,
                    calendarTime: hours + ':' + minutes,
                },
                tickets: [ticket]
            });
        } else {
            result.data[indexOfTicket].tickets.push(ticket);
        }

        result.metadata.count++;
        result.metadata.sum += (+ticket.price);
    });

    return result;
}

import {Component} from '@angular/core';
import {LoaderService} from './loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

    /**
     * Constructor
     */
    constructor(private loaderService: LoaderService) {
    }

    isOpen() {
        return this.loaderService.isShowPopup;
    }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { PipesModule } from '@app/_core/pipes/pipes.module';

import { SmileToggleModule } from '@app/content/components/smile-toggle/smile-toggle.module';
import {TicketCardNewComponent} from '@app/content/components/ticket-card-new/ticket-card-new.component';
import {MatRadioModule} from '@angular/material/radio';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [TicketCardNewComponent],
    imports: [
        CommonModule,
        AngularSvgIconModule,
        PipesModule,
        SmileToggleModule,
        MatRadioModule,
        ReactiveFormsModule
    ],
  exports: [
    TicketCardNewComponent,
    AngularSvgIconModule,
    PipesModule,
    SmileToggleModule
  ]
})
export class TicketCardNewModule { }

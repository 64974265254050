import {Component, OnInit} from '@angular/core';
import {ModalService} from '@app/shared/modal-service/modal.service';
import {ScaleElementAnimation} from '@app/_core/animation/animations';

@Component({
    selector: 'app-with-child-modal',
    templateUrl: './with-child-modal.component.html',
    styleUrls: ['./with-child-modal.component.scss'],
    animations: [ScaleElementAnimation]
})
export class WithChildModalComponent implements OnInit {

    constructor(
        private readonly modalService: ModalService
    ) {
    }

    ngOnInit() {
    }

    close(status: boolean) {
        this.modalService
            .setResponse(status)
            .close();
    }
}

import {
  animate,
  animateChild,
  AnimationTriggerMetadata, keyframes,
  query, stagger,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

/**
 * Value: триггер анимации сжатия/растяжения элементов от нулевой высоты до
 * необходимой, используется для открытия/закрытия дропдауна
 * @type {Function}
 */
export const collapseToggleAnimation: AnimationTriggerMetadata = trigger('collapseToggle', [
  transition(':enter', [
    style({height: 0}),
    animate('.5s ease-in-out', style({height: '*'}))
  ]),
  transition(':leave', [
    style({height: '*'}),
    animate('.5s ease-in-out', style({height: 0}))
  ])
]);

/**
 * Value: триггер анимации плавного уменьшения/увеличения прозрачности элемента
 * @type {Function}
 */
export const fadeToggleAnimation: AnimationTriggerMetadata = trigger('fadeToggle', [
  transition(':enter', [
    style({opacity: 0}),
    animate('.3s', style({opacity: 1}))
  ]),
  transition(':leave', [
    style({opacity: 1}),
    animate('.3s', style({opacity: 0}))
  ])
]);

/**
 * Value: триггер анимации поворота элемента на 180 градусов, используется
 * для стрелок дропдауна
 * @type {Function}
 */
export const rotateArrowAnimation: AnimationTriggerMetadata = trigger('rotateArrow', [
  state('false', style({
    transform: 'none'
  })),
  state('true', style({
    transform: 'rotate(180deg)'
  })),
  transition('true => false', [
    style({transform: 'rotate(180deg)'}),
    animate('.3s', style({transform: 'none'}))
  ]),
  transition('false => true', [
    style({transform: 'none'}),
    animate('.3s', style({transform: 'rotate(180deg)'}))
  ])
]);

/**
 * Value: триггер анимации увеличения/уменьшения размера элемента от 0 до
 * 100% высоты + анимиррование потомков элемента
 * @type {Function}
 */
export const iconScaleAnimation: AnimationTriggerMetadata = trigger('iconScale', [
  transition(':enter', [
    style({transform: 'scale(0)'}),
    animate('.3s ease-in-out', style({transform: 'scale(1)'})),
    query('@fadeFromBottom', [
      stagger('300ms', [
        animateChild()
      ])
    ], {optional: true})
  ]),
  transition(':leave', [
    style({transform: 'scale(1)'}),
    style({transform: 'scale(0)'})
  ])
]);

/**
 * Value: триггер анимации увеличения/уменьшения размера элемента от 1 до 110%
 * его величины
 * @type {Function}
 */
export const stageScaleAnimation: AnimationTriggerMetadata = trigger('stageScale', [
  state('false', style({
    transform: 'scale(1)'
  })),
  state('true', style({
    transform: 'scale(1.1)'
  })),
  transition('true => false', [
    style({transform: 'scale(1.1)'}),
    animate('.2s', style({transform: 'scale(1)'})),
    query('@iconScale', [
      animateChild()
    ], {optional: true})
  ]),
  transition('false => true', [
    style({transform: 'scale(1)'}),
    animate('.2s', style({transform: 'scale(1.1)'})),
    query('@iconScale', [
      animateChild()
    ], {optional: true})
  ])
]);

/**
 * Value: триггер анимации увеличения/уменьшения размера элемента
 * @type {Function}
 */
export const ScaleElementAnimation: AnimationTriggerMetadata = trigger('ScaleElement', [
  transition(':enter', [
    style({transform: 'scale(0)'}),
    animate('.4s ease-in-out', style({transform: 'scale(1)'}))
  ]),
  transition(':leave', [
    style({transform: 'scale(1)'}),
    animate('.4s ease-in-out', style({transform: 'scale(0)'}))
  ])
]);

/**
 * Value: триггер анимации плавного появления элемента снизу
 * @type {Function}
 */
export const fadeFromBottomAnimation: AnimationTriggerMetadata = trigger('fadeFromBottom', [
  transition('* => *', [
    query(':enter', [
      style({transform: 'translateY(50px)', opacity: 0})
    ], {optional: true}),
    query(':enter', [
      stagger('100ms', [
        animate('.3s ease-in-out', style({transform: 'none', opacity: 1}))
      ])
    ], {optional: true})
  ])
]);

/**
 * Value: триггер анимации плавного появления элемента сверху вниз
 * @type {Function}
 */
export const fadeFromTopToBottomAnimation: AnimationTriggerMetadata = trigger('fadeFromTopToBottom', [
  transition('* => *', [
    query(':enter', [
      style({opacity: 0})
    ], {optional: true}),
    query(':enter', [
      stagger('300ms', [
        animate('1s ease-in', keyframes([
          style({transform: 'translateY(-75px)', opacity: 0, offset: 0}),
          style({transform: 'translateY(35px)', opacity: 0.5, offset: 0.3}),
          style({transform: 'translateY(0)', opacity: 1, offset: 1})
        ]))
      ])
    ], {optional: true}),
    query(':leave', [
      stagger('300ms', [
        animate('1s ease-in', keyframes([
          style({transform: 'translateY(0)', opacity: 1, offset: 0}),
          style({transform: 'translateY(35px)', opacity: 0.5, offset: 0.3}),
          style({transform: 'translateY(75px)', opacity: 0, offset: 1})
        ]))
      ])
    ], {optional: true})
  ])
]);

/**
 * Value: триггер анимации плавного появления элемента (элементов по селектору) слева
 * @type {Function}
 */
export const fadeFromLeftAnimation: AnimationTriggerMetadata = trigger('fadeFromLeft', [
  transition('* => *', [
    query('.elem', [
      style({transform: 'translateX(-40px)', opacity: 0})
    ], {optional: true}),
    query('.elem', [
      stagger('500ms', [
        animate('800ms 1.2s ease-out', style({transform: 'translateX(0)', opacity: 1}))
      ])
    ])
  ])
]);

import { Injectable } from '@angular/core';
import { NavigationEnd, Params, Router, RouterEvent } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, share, skipWhile } from 'rxjs/operators';

import { Modal } from '@app/models/shared/modal.interface';


/**
 * Декоратор Injectable и метаданные
 *
 * @Annotation
 * @publicApi
 */
@Injectable({
  providedIn: 'root'
})
export class ModalService<T1 extends object = object> {

  /**
   * value: modal$
   * @type {BehaviorSubject<Modal>}
   * Модалка
   */
  private modal$: BehaviorSubject<Modal> = new BehaviorSubject(null);

  /**
   * value: modalData$
   * @type {BehaviorSubject<object>}
   * Данные модалки
   */
  private modalData$: BehaviorSubject<T1> = new BehaviorSubject(null);

  /**
   * value: modalData$
   * @type {BehaviorSubject<object>}
   * Данные модалки
   */
  private modalResponse$: BehaviorSubject<object | string | number | boolean> = new BehaviorSubject(null);

  /**
   * value: modalData$
   * @type {BehaviorSubject<object>}
   * Данные модалки
   */
  private inQueue: boolean = false;

  /**
   * Имя текушей модалки
   */
  private name: string = '';

  /**
   * Последняя позиция окна
   */
  private lastBodyPosition: number;

  /**
   * Constructor
   * @param {Router} router
   */
  constructor(
    private router: Router
  ) {
    this.router.events
      .pipe(filter((res: RouterEvent) => res instanceof NavigationEnd))
      .subscribe((res: Params) => {
        if (this.modal$.value && this.modal$.value.isOpen && res) {
          this.close();
        }
      });
  }

  /**
   * Открытие модального окна
   * @param {string} name
   * @param {object} data
   */
  open(name: string, data: T1 = null): Observable<object | string | number | boolean> {
    this.name = name;
    const modal: Modal = {
      name: name,
      isOpen: true,
      data: data
    };

    this.modal$.next(modal);
    this.modalData$.next(data);
    this.disableScroll();
    return this.getResponse(name);
  }

  /**
   * Метод: включить очередь модальных окон
   */
  enableQueue(): this {
    this.inQueue = true;
    return this;
  }

  /**
   * Закрытие модального окна
   * @param {string} name
   */
  close(name: string = ''): void {
    this.name = null;
    const modal: Modal = {
      name: name,
      isOpen: false
    };
    if (!this.inQueue) {
      this.modal$.next(modal);
      this.modalData$.next(null);
      this.modalResponse$.next(null);
    }
    this.inQueue = false;
    this.enableScroll();
  }


  setResponse(data: object | string | number | boolean): this {
    const response: any = {name: this.name, data: data};
    this.modalResponse$.next(response);
    return this;
  }

  /**
   * Получение ответа от модалки
   * @param {string} name
   */
  getResponse(name: string = null): Observable<object | string | number | boolean> {
    return this.modalResponse$
      .pipe(map((result: any) => {
        if (result && !result.name) {
          result.name = name;
        }
        if (!result || result.name !== name) {
          return null;
        }
        return result.data;
      }))
      .pipe(skipWhile((res: object | string | number) => {
        return res === undefined || res === null;
      }))
      .pipe(distinctUntilChanged())
      .pipe(share());
  }

  /**
   * Получить модалку
   */
  getModal(): BehaviorSubject<Modal> {
    return this.modal$;
  }

  /**
   * Получить данные текущей модалки
   */
  getData(): BehaviorSubject<T1> {
    return this.modalData$;
  }

  /**
   * Установить данные текущей модалки
   * @param {T1} data
   */
  setData(data: T1 = null): void {
    this.modalData$.next(data);
  }

  /**
   * Метод: выключение скролла
   */
  public disableScroll(): void {
    const documentScrollTop: number = (document.documentElement && document.documentElement.scrollTop) ||
      (window.document && window.document.documentElement && window.document.documentElement.scrollTop) ||
      (document.scrollingElement && document.scrollingElement.scrollTop) ||
      window.scrollY || document.body.scrollTop;

    const isIosDevice: boolean =
      typeof window !== 'undefined' &&
      window.navigator &&
      window.navigator.platform &&
      /iP(ad|hone|od)/.test(window.navigator.platform);

    document.body.classList.add('scroll-lock');

    if (isIosDevice) {
      document.body.style.top = `-${documentScrollTop}px`;
      document.body.style.position = 'fixed';
      this.lastBodyPosition = documentScrollTop;
    }
  }

  /**
   * Метод: включение скролла
   */
  public enableScroll(): void {
    const isIosDevice: boolean =
      typeof window !== 'undefined' &&
      window.navigator &&
      window.navigator.platform &&
      /iP(ad|hone|od)/.test(window.navigator.platform);

    document.body.classList.remove('scroll-lock');

    if (isIosDevice && this.lastBodyPosition !== undefined) {
      window.scrollTo(0, this.lastBodyPosition);
      this.lastBodyPosition = null;
      document.body.style.position = '';
    }
  }
}

import {Component, HostListener, Input, OnInit} from '@angular/core';

import { AnimateScrollToHelper } from '@app/_core/helpers/animate-scroll-to.helper';

import { ApiService } from '@app/shared/api/api.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() isScrollUpHidden: boolean;

  footerLayout: string;

  public screenWidth: string;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = this._footerWidth();
  }

  constructor(private apiService: ApiService) {
    this.screenWidth = this._footerWidth();
  }

  ngOnInit() {
  }

  scrollToTop(): void {
    AnimateScrollToHelper.scrollTo(0, 200);
  }

  private _footerWidth(): string {
    const width = document.documentElement.clientWidth;
    return width < 1240 ? 'calc(' + width + 'px' + ' - ' + '5%)' : '1200px';
  }

}

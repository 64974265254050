import { CommonModule } from '@angular/common';
import {NgModule} from '@angular/core';
import {BasketSidebarComponents} from '@app/_core/components/basket-sidebar/basket-sidebar.components';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {TicketCardNewModule} from '@app/content/components/ticket-card-new/ticket-card-new.module';
import {MatButtonModule} from "@angular/material/button";
import {MomentModule} from "ngx-moment";

@NgModule({
  declarations: [BasketSidebarComponents],
  exports: [BasketSidebarComponents],
    imports: [
        CommonModule,
        AngularSvgIconModule,
        TicketCardNewModule,
        MatButtonModule,
        MomentModule,
    ]
})
export class BasketSidebarModule { }

import { Component, OnInit } from '@angular/core';
import {BasketSidebarService} from '@app/_core/components/basket-sidebar/services/basket-sidebar.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-basket-sidebar-widget',
  templateUrl: './basket-sidebar-widget.component.html',
  styleUrls: ['./basket-sidebar-widget.component.scss']
})
export class BasketSidebarWidgetComponent implements OnInit {
  public ticketsCount$: Observable<number> = this.basketSidebarService.getTrashItemsCount();
  public currentPrice$: Observable<number> = this.basketSidebarService.getCurrentPrice();

  constructor(private basketSidebarService: BasketSidebarService) { }

  ngOnInit() {
  }
  public toggleSidebar() {
    this.basketSidebarService.toggleSidebar();
  }
}

import {Component, OnInit} from '@angular/core';
import {LoaderService} from '@app/content/components/loader/loader.service';
import emailMask from 'text-mask-addons/dist/emailMask';
import {ApiService} from '@app/shared/api/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UpdateEmailStateEnum} from '@app/content/components/update-email/update-email-state.enum';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-update-email',
    templateUrl: './update-email.component.html',
    styleUrls: ['./update-email.component.scss']
})
export class UpdateEmailComponent implements OnInit {
    public emailUpdateStateEnum: typeof UpdateEmailStateEnum = UpdateEmailStateEnum;
    public emailUpdateState: string = '';
    public emailMask = emailMask;
    public emailGroup: FormGroup = this.fb.group({
        email: this.fb.control(null, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]),
        confirmEmail: this.fb.control(null, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]),
    });

    constructor(
        private api: ApiService,
        private loaderService: LoaderService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder
    ) {
        this.emailUpdateState = this.route.snapshot.url[0].path;
    }

    ngOnInit() {
    }

    public changeEmail(): void {
        this.loaderService.open({min: environment.preloaderDelay});
        this.api.updateEmail({email: this.emailGroup.get('email').value})
            .then(() => this.emailUpdateState = this.emailUpdateStateEnum.MESSAGE_SENT)
            .finally(() => this.loaderService.close());
    }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loyalityst',
  templateUrl: './loyalityst.component.html',
  styleUrls: ['./loyalityst.component.scss']
})
export class LoyalitystComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import intlTelInput from 'intl-tel-input';

declare var intlTelInputUtils: any;

@Component({
    selector: 'app-phone-input',
    templateUrl: './phone-input.component.html',
    styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements AfterViewInit, OnDestroy, OnChanges {

    @Input()
    phoneNumber: string;

    @Input()
    disabled = false;

    @Output()
    numberChange: EventEmitter<{ phone: string, valid: boolean, formattedPhone: string }> = new EventEmitter();

    @ViewChild('phone', {static: true}) phoneRef: ElementRef;

    public phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];

    private iti: any = null;

    constructor(
        private readonly elRef: ElementRef
    ) {
    }

    ngAfterViewInit(): void {
        const input = this.phoneRef.nativeElement;

        input.setAttribute('value', this.phoneNumber);

        this.iti = intlTelInput(input, {
            nationalMode: true,
            formatOnDisplay: true,
            separateDialCode: true,
            initialCountry: 'ru',
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.js'
        });

        if (this.disabled) {
            input.setAttribute('readonly', 'true');
            const flagRef = this.elRef.nativeElement.querySelector('.iti__selected-flag');
            flagRef.setAttribute('disabled', 'disabled');
        }

        const formatIntlTelInput = () => {
            if (typeof intlTelInputUtils !== 'undefined') {
                const currentText = this.iti.getNumber(intlTelInputUtils.numberFormat.E164);
                if (typeof currentText === 'string') {
                    const formattedString = currentText.replace(/[^+()\- \d]/g, '').slice(0, 16);
                    this.iti.setNumber(formattedString);

                    this.numberChange.emit({
                        phone: this.iti.getNumber(intlTelInputUtils.numberFormat.E164).replace(/[^\d]/g, ''),
                        valid: this.iti.isValidNumber(formattedString),
                        formattedPhone: formattedString
                    });
                }
            }
        };

        input.addEventListener('keyup', formatIntlTelInput);
        input.addEventListener('change', formatIntlTelInput);
        input.addEventListener('countrychange', formatIntlTelInput);
    }

    ngOnChanges(changes: SimpleChanges) {
        const input = this.phoneRef.nativeElement;
        input.setAttribute('value', this.phoneNumber);
    }

    ngOnDestroy() {
        if (this.iti !== null) {
            this.iti.destroy();
        }
    }

    @HostBinding('class.disabled')
    get inputDisabled() {
        return this.disabled;
    }
}

import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable, Subscription} from "rxjs";
import {ApiService} from "@app/shared/api/api.service";
import {ModalService} from "@app/shared/modal-service/modal.service";

export class AccessGuard implements CanActivate {
    constructor(private _api: ApiService, private router: Router, private modalService: ModalService) {
    }
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        try {
            await this._api.getCurrentUser();
            return true;
        } catch (e) {
            this.router.navigate(['/']).then(() => {
                const modalSubscription: Subscription = this.modalService.open('login-modal')
                    .subscribe((data) => {
                        modalSubscription.unsubscribe();
                    });
            });
            return false;
        }
    }
}

/**
 * Хелпер для плавного скролла
 */
export class AnimateScrollToHelper {

  /**
   * Метод: анимирование (плавный) скроллинг
   * @param {number} to
   * @param {number} duration
   */
  static scrollTo(to: number, duration: number): void {
    const start: number = (document.documentElement && document.documentElement.scrollTop) ||
      (window.document && window.document.documentElement && window.document.documentElement.scrollTop) ||
      (document.scrollingElement && document.scrollingElement.scrollTop) ||
      window.scrollY || document.body.scrollTop;
    const change: number = to - start,
      increment: number = 20;
    let currentTime: number = 0;

    const easeInOutQuad: Function = (t: number, b: number, c: number, d: number): number => {
      t /= d / 2;
      if (t < 1) {
        return c / 2 * t * t + b;
      }
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    const animateScroll: Function = (): void => {
      currentTime += increment;
      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = easeInOutQuad(currentTime, start, change, duration);
      } else {
        document.documentElement.scrollTop = easeInOutQuad(currentTime, start, change, duration);
      }
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }
}

import {NgModule} from '@angular/core';

import {DecimalPipe} from '@app/_core/pipes/decimap.pipe';
import {SafeHtmlPipe} from '@app/_core/pipes/safe-html.pipe';
import {SafePipe} from '@app/_core/pipes/safe.pipe';
import {SearchQuestionPipe} from '@app/_core/pipes/search-question.pipe';
import {SplitPipe} from '@app/_core/pipes/split.pipe';
import {SplitDatePipe} from '@app/_core/pipes/splitDate.pipe';
import {SplitTimePipe} from '@app/_core/pipes/splitTime';
import {CurrencyFormatPipe} from '@app/_core/pipes/currency-format.pipe';

@NgModule({
    declarations: [
        SafePipe,
        SplitPipe,
        DecimalPipe,
        SafeHtmlPipe,
        SplitDatePipe,
        SplitTimePipe,
        SearchQuestionPipe,
        CurrencyFormatPipe
    ],
    exports: [
        SafePipe,
        SplitPipe,
        DecimalPipe,
        SafeHtmlPipe,
        SplitDatePipe,
        SplitTimePipe,
        SearchQuestionPipe,
        CurrencyFormatPipe
    ]
})
export class PipesModule {
}

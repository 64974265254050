import { Component, OnInit } from '@angular/core';
import {BasketSidebarService} from '@app/_core/components/basket-sidebar/services/basket-sidebar.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {
  public ticketsInTrashCount$: Observable<number> = this.basketSidebarService.getTrashItemsCount();

  public constructor(private basketSidebarService: BasketSidebarService) {}
  ngOnInit() {
  }

}

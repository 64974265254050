import { Component, OnInit } from '@angular/core';
import {ApiService} from '@app/shared/api/api.service';
import {LoaderService} from '../loader/loader.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-update-phone',
  templateUrl: './update-phone.component.html',
  styleUrls: ['./update-phone.component.scss']
})
export class UpdatePhoneComponent implements OnInit {
  public phoneUpdateState: string = 'PhoneInput';
  public phoneNumber: any = {
    phone: '',
    isValid: false
  };
  public codeControl: FormControl = this.fb.control(null, Validators.required);
  public isConfirmError: boolean = true;
  private token: string;
  constructor(private api: ApiService,
              private loaderService: LoaderService,
              private router: Router,
              private fb: FormBuilder) { }

  ngOnInit() {
  }

  public onPhoneChange(phoneNumberInfo: {phone: string, valid: boolean, formattedPhone: string }): void {
      this.phoneNumber = {
        isValid: phoneNumberInfo.valid,
        phone: phoneNumberInfo.formattedPhone
      };
  }

  public changePhone(): void {
    this.loaderService.open({min: environment.preloaderDelay});
    this.api.updatePhone({mobilePhone: this.phoneNumber.phone})
        .then((token) => {
          this.phoneUpdateState = 'SMSConfirm';
          this.token = token;
        })
        .finally(() => this.loaderService.close());
  }

  public confirmSMS(): void {
    this.loaderService.open({min: environment.preloaderDelay});
    this.api.updatePhoneSMSConfirm({code: this.codeControl.value, token: this.token})
        .then(() => {
          this.router.navigate(['/lk']);
        })
        .catch(() => this.codeControl.setErrors({wrongCode: true}))
        .finally(() => this.loaderService.close());
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmileToggleComponent } from '@app/content/components/smile-toggle/smile-toggle.component';

@NgModule({
  declarations: [SmileToggleComponent],
  imports: [
    CommonModule
  ],
  exports: [SmileToggleComponent]
})
export class SmileToggleModule { }

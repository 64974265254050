import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

import {filter, map, mergeMap, skip} from 'rxjs/operators';

import {ModalService} from '@app/shared/modal-service/modal.service';

import {AnimateScrollToHelper} from '@app/_core/helpers/animate-scroll-to.helper';

import {Modal} from '@app/models/shared/modal.interface';
import {Observable} from 'rxjs';
import {BasketSidebarService} from '@app/_core/components/basket-sidebar/services/basket-sidebar.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

    /**
     * value: modalName
     * @type {string}
     * Активное модальное окно
     */
    modalName = '';
    public ticketsCount$: Observable<number> = this.basketSidebarService.getTrashItemsCount();

    constructor(private router: Router,
                private route: ActivatedRoute,
                private titleService: Title,
                private modal: ModalService,
                private basketSidebarService: BasketSidebarService,
    ) {

    }

    ngOnInit() {
        this.routerChangeSubscription();
    }

    ngAfterViewInit(): void {
        this.modal.getModal()
            .pipe(skip(1))
            .subscribe((modal: Modal) => {
                if (!modal) {
                    this.modalName = '';
                    return;
                }
                this.modalName = modal.isOpen ? modal.name : '';
            });
    }

    routerChangeSubscription() {
        this.router
            .events
            .pipe(
                filter(e => e instanceof NavigationEnd),
                map(() => {
                    let route = this.route.firstChild;
                    let child = route;
                    while (child) {
                        if (child.firstChild) {
                            child = child.firstChild;
                            route = child;
                        } else {
                            child = null;
                        }
                    }
                    return route;
                }),
                mergeMap(route => route.data)
            )
            .subscribe(data => {
                AnimateScrollToHelper.scrollTo(0, 0);
                this.titleService.setTitle(data.title);
            });
    }
}

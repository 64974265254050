import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {AngularSvgIconModule} from 'angular-svg-icon';
import {NgxMaskModule} from 'ngx-mask';

import {AppRoutingModule} from './app-routing.module';
import {LoaderModule} from '@app/content/components/loader/loader.module';
import {PipesModule} from '@app/_core/pipes/pipes.module';

import {OnlyNumberDirective} from '@app/_core/directives/only-number.directive';

import {AppComponent} from './app.component';
import {HeaderComponent} from './content/components/header/header.component';
import {FooterComponent} from './content/components/footer/footer.component';
import {ContainerComponent} from '@app/content/containers/container/container.component';
import {VipApplicationModalComponent} from './_core/modals/vip-application-modal/vip-application-modal.component';
import {WarningModalComponent} from '@app/_core/modals/warning-modal/warning-modal.component';
import {TicketsModalComponent} from '@app/_core/modals/tickets-modal/tickets-modal.component';
import {NotFoundComponent} from '@app/content/containers/not-found/not-found.component';
import {LoginModalComponent} from '@app/_core/modals/login-modal/login-modal.component';
import {LimitedViewModalComponent} from '@app/_core/modals/limited-view-modal/limited-view-modal.component';
import {EventsListModalComponent} from './_core/modals/events-list-modal/events-list-modal.component';
import {EventItemModule} from '@app/content/components/event-item/event-item.module';
import {WithChildModalComponent} from './_core/modals/with-child-modal/with-child-modal.component';
import {SimpleAuthCheckComponent} from './_core/modals/simple-auth-check/simple-auth-check.component';
import {LoanApplicationModalComponent} from '@app/_core/modals/loan-application-modal/loan-application-modal.component';
import {PhoneInputModule} from '@app/_core/components/phone-input.module';
import { AccessGuard } from '@app/_core/guards/access.guard';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { TextMaskModule } from 'angular2-text-mask';
import { UpdatePhoneComponent } from './content/components/update-phone/update-phone.component';
import { UpdateEmailComponent } from './content/components/update-email/update-email.component';
import { OfertaComponent } from './content/containers/oferta/oferta.component';
import { LoyalityComponent } from './content/containers/loyality/loyality.component';
import { AgreementComponent } from './content/containers/agreement/agreement.component';
import { BasketSidebarModule } from '@app/_core/components/basket-sidebar/basket-sidebar.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ImpressionComponent } from './content/containers/impression/impression.component';
import { LoyalitystComponent } from './content/containers/loyalityst/loyalityst.component';

@NgModule({
    declarations: [
        AppComponent,
        ContainerComponent,
        HeaderComponent,
        FooterComponent,
        NotFoundComponent,
        VipApplicationModalComponent,
        TicketsModalComponent,
        LoginModalComponent,
        WarningModalComponent,
        OnlyNumberDirective,
        LimitedViewModalComponent,
        EventsListModalComponent,
        WithChildModalComponent,
        SimpleAuthCheckComponent,
        LoanApplicationModalComponent,
        UpdatePhoneComponent,
        UpdateEmailComponent,
        OfertaComponent,
        LoyalityComponent,
        AgreementComponent,
        ImpressionComponent,
        LoyalitystComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        AngularSvgIconModule,
        NgxMaskModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        LoaderModule,
        PipesModule,
        EventItemModule,
        PhoneInputModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        TextMaskModule,
        BasketSidebarModule,
        MatSnackBarModule
    ],
    providers: [AccessGuard, {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'}],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {

  storedValues = [
    'currentUserQuantity',
    'currentUser',
  ];

  store = {
    mainSiteMy: 'https://my.hawk.ru',
    mainSite: 'https://hawk.ru'
  };

  constructor() {
    this.storedValues.forEach((key) => {
      this.store[key] = JSON.parse(localStorage.getItem(key));
    });
  }

  removeItem(key): void {
    delete this.store[key];
  }

  getItem(key): any {
    return this.store[key];
  }

  setItem(key, value): void {
    this.store[key] = value;
    if (this.storedValues.indexOf(key) > -1) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimal'
})
export class DecimalPipe implements PipeTransform {

  transform(val: string): number {
    if (val !== undefined && val !== null) {
      return Number(val);
    } else {
      return 0;
    }
  }
}

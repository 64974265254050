import {Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, of, Subject} from 'rxjs';
import {IRequestObject} from '@app/models/shared/request-object.interface';
import {HttpClient} from '@angular/common/http';
import {GlobalDataService} from '@app/shared/global-data/global-data.service';
import {ApiService} from '@app/shared/api/api.service';
import {ReservedSeat} from '@app/content/containers/places/scheme.types';
import {groupTrash} from '@app/_core/utils/group-trash.utils';
import {switchMap, tap} from 'rxjs/operators';
import {BasketSidebarPayload} from '@app/_core/components/basket-sidebar/types/basket-sidebar.type';

@Injectable({
  providedIn: 'root'
})
export class BasketSidebarService {
  private sidebarStatus$$ = new BehaviorSubject<boolean>(false);
  private itemDeleted$$ = new Subject<any>();
  private trash$$ = new BehaviorSubject<any>(null);
  private trashItemsCount$$ = new BehaviorSubject<number>(0);
  private currentPrice$$ = new BehaviorSubject<number>(0);


  constructor(private readonly httpClient: HttpClient,
              private globalDataService: GlobalDataService,
              private api: ApiService,
  ) {
  }

  public toggleSidebar(): void {
    const value = !this.sidebarStatus$$.value;
    this.sidebarStatus$$.next(value);
  }

  public closeSidebar(): void {
    this.sidebarStatus$$.next(false);
  }

  public sidebarToggled$(): Observable<boolean> {
    return this.sidebarStatus$$.asObservable();
  }

  public setTrash(trash: any) {
    this.trash$$.next(trash);
    this.setTrashItemsCount(trash.metadata.count);
    this.setCurrentPrice(trash.metadata.sum);
  }

  public getTrash(): Observable<any> {
    return this.trash$$.asObservable()
        .pipe(
            tap((trashData) => {
              if (trashData) {
                const { data } = trashData;
                if (data && data.length) {
                  for (let i = 0; i <= data.length - 1; i++) {
                    for (let j = 0; j <= data[i].tickets.length - 1; j++) {
                      if (data[i].tickets[j].availablePrices.length > 1) {
                        data[i].tickets[j].availablePrices = data[i].tickets[j].availablePrices
                            .sort((a, b) =>  a.categoryOrder - b.categoryOrder);
                      }
                    }
                  }
                }
              }
            })
        );
  }

  public deleteItemInSector(data: any): void {
    const {categoryUuid} = data;
    this.itemDeleted$$.next({data, categoryUuid});
  }

  public itemDeletedInSidebarTrash(): Observable<any> {
    return this.itemDeleted$$.asObservable();
  }

  public setTrashItemsCount(count: number): void {
    this.trashItemsCount$$.next(count);
  }

  public getTrashItemsCount(): Observable<number> {
    return this.trashItemsCount$$.asObservable();
  }

  public setCurrentPrice(price: number): void {
    this.currentPrice$$.next(price);
  }

  public getCurrentPrice(): Observable<number> {
    return this.currentPrice$$.asObservable();
  }

  public resetBasketWidget(): void {
    this.trash$$.next([]);
    this.trashItemsCount$$.next(0);
    this.currentPrice$$.next(0);
    this.sidebarStatus$$.next(false);
  }

  public removeFromTrash(ticket: { uuid: string, price: number, eventId: string }): void {
    const trash = this.trash$$.value;
    trash.data.forEach((game, index) => {
      if (game.game.eventSessionUuid === ticket.eventId) {
        game.tickets = game.tickets.filter((item: ReservedSeat) => item.locationSchemeSeatUuid !== ticket.uuid);
        if (!game.tickets.length) {
          trash.data.splice(index, 1);
        }
        trash.metadata.count--;
        trash.metadata.sum -= (+ticket.price);
      }
    });
    this.setTrash(trash);
  }

  public async loadCurrentOrder(): Promise<void> {
    if (this.globalDataService.getItem('currentUser')) {
      try {
        const requestObject: IRequestObject = {
          urlAdd: `/webapi/reserved-seat/current`
        };
        const reservedSeats = await this.api.get(requestObject);
        const reservedSeatsMap: Map<string, ReservedSeat> = new Map();
        reservedSeats
            .forEach(reservedSeat => {
              reservedSeatsMap.set(`${reservedSeat.eventSessionUuid}_${reservedSeat.locationSchemeSeatUuid}`, reservedSeat);
            });
        this.setTrash(groupTrash(Array.from(reservedSeatsMap.values())));
      } catch (e) {
      }
    }
  }

  public removeItem(data: any): Observable<any> {
    const eventId = data.eventSessionUuid;
    const uuid = data.locationSchemeSeatUuid;
    return this.loadReservedSeats().pipe(
        switchMap((seatsMap) => {
          const seatToUnreserve: Map<string, ReservedSeat> = seatsMap;
          const seat = seatToUnreserve.get(`${eventId}_${uuid}`);
          const model = [{
            eventUuid: eventId,
            seatUuid: uuid,
            categoryUuid: seat.categoryUuid,
            price: seat.price
          }];

          return this.unReserveSeats(model);
        })
    );
  }

  public loadReservedSeats(): Observable<any> {
    if (this.globalDataService.getItem('currentUser')) {
      return this.httpClient.get(`/webapi/reserved-seat/current`)
          .pipe(
              switchMap((data: any[]) => {
                const reservedSeatsMap = new Map();
                data
                    .forEach(reservedSeat => {
                      reservedSeatsMap.set(`${reservedSeat.eventSessionUuid}_${reservedSeat.locationSchemeSeatUuid}`, reservedSeat);
                    });
                return of(reservedSeatsMap);
              })
          );
    } else {
      return EMPTY;
    }
  }

  public unReserveSeats(seats: { eventUuid: string, seatUuid: string, categoryUuid: string, price: number }[]): Observable<any> {
    const groupedSeats = seats
        .reduce((accumulator: { [key: string]: { locationSchemeSeatUuid: string; categoryUuid: string }[] }, seat) => {
          if (!(accumulator[seat.eventUuid] instanceof Array)) {
            accumulator[seat.eventUuid] = [];
          }
          accumulator[seat.eventUuid].push({
            locationSchemeSeatUuid: seat.seatUuid,
            categoryUuid: seat.categoryUuid
          });
          return accumulator;
        }, {});

    const model = Object.keys(groupedSeats).map(eventUuid => {
      return {
        eventSessionUuid: eventUuid,
        locationSchemeSeats: groupedSeats[eventUuid]
      };
    });

    return this.httpClient.put<ReservedSeat[]>(`/webapi/reserved-seat/unreserve`, model);
  }

  public changeTicketCategory(model: BasketSidebarPayload): Observable<any> {
    return this.httpClient.put(`/webapi/reserved-seat/category`, model).pipe(
        tap((value: ReservedSeat[]) => {
      this.setTrash(groupTrash(value));
    }));
  }
}

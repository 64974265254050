import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';

@Component({
  selector: 'app-ticket-card-new',
  templateUrl: './ticket-card-new.component.html',
  styleUrls: ['./ticket-card-new.component.scss']
})
export class TicketCardNewComponent implements OnInit {

  @Input() set data(value) {
    this.priceControl.setValue(value.categoryUuid, {emitEvent: false});
    this._data = value;
  }
  @Input() editMode = false;
  @Input() onRemove: (ticket: any) => {};
  @Input() onChangeStatus: (data: any) => {};
  @Input() onChangeCategory: (data: any) => {};
  @Input() enableTicketActions = false;
  @Input() isSubscriptionSeat = false;

  @Input() onWallet: (ticket: any) => {};
  @Input() onPrint: (id: number) => {};
  @Input() onEmail: (id: number) => {};
  @Input() onEventsList: (ticket: any) => {};

  @Input() first: any;
  @Input() second: any;
  @Input() withCategoryChoice = false;
  @Input() showCurrentCategory = false;
  public priceControl: FormControl = this.fb.control('');
  // tslint:disable-next-line:variable-name
  public _data: any;


  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.priceControl.valueChanges.subscribe((categoryUuid: string) => {

      this.onChangeCategory({
        categoryUuid,
        eventSessionUuid: this._data.eventSessionUuid,
        locationSchemeSeatUuid: this._data.locationSchemeSeatUuid,
      });
    });
  }

  // getCurrentWindowWidth() {
  //   return window.innerWidth;
  // }


}

import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';

import {Subscription} from 'rxjs';

import {ScaleElementAnimation} from '@app/_core/animation/animations';

import {ModalService} from '@app/shared/modal-service/modal.service';
import {LoaderService} from '@app/content/components/loader/loader.service';
import {ApiService} from '@app/shared/api/api.service';
import {GlobalDataService} from '@app/shared/global-data/global-data.service';

import {environment} from '../../../../environments/environment';
import {AuthResult} from '@app/_core/modals/login-modal/login-modal.component';

@Component({
    selector: 'app-tickets-modal',
    templateUrl: './tickets-modal.component.html',
    styleUrls: ['./tickets-modal.component.scss'],
    animations: [ScaleElementAnimation]
})
export class TicketsModalComponent implements OnInit {

    subscription: Subscription;

    data: any;

    count: number = 1;
    maxCount: number = 10;

    constructor(
        private formBuilder: FormBuilder,
        private modalService: ModalService,
        private loaderService: LoaderService,
        private apiService: ApiService,
        private globalDataService: GlobalDataService,
    ) {
    }

    ngOnInit() {
        this.subscription = this.modalService.getData()
            .subscribe((data: any) => {
                this.data = data;
                if (this.data && this.data.limit) {
                    this.maxCount = this.data.limit;
                }
            });
    }

    close(status: boolean): void {
        if (status && this.data && this.data.sector && this.data.calendarId) {
            this.loaderService.open({min: environment.preloaderDelay});
            this.tryReservePlaces(status);
        } else {
            this.modalService
                .setResponse({...this.data, status})
                .close();
            this.subscription.unsubscribe();
        }
    }

    private async tryReservePlaces(status) {
        try {
            await this.reservePlaces(status);
        } catch (e) {
            if (e.status === 401) {
                try {
                    await this.processAuth();
                    await this.reservePlaces(status);
                } catch (e) {

                }
            }
        }
    }

    private reservePlaces(status) {
        return this.apiService.reservePlace(this.data.calendarId, {
            seatId: this.data.sector.id,
            quantity: +this.count
        })
            .then(() => {
                const windowObj: any = window;
                let clientId;
                try {
                    const getAll: any = windowObj.ga ? windowObj.ga.getAll() : null;
                    clientId = getAll && getAll[0] ? getAll[0].get('clientId') : null;
                } catch (e) {

                }
                this.apiService.createOrder(clientId, false)
                    .then((order) => {
                        this.globalDataService.setItem('currentUserQuantity', this.globalDataService.getItem('currentUserQuantity') + 1);
                        this.data.order = order;
                        this.loaderService.close();
                        this.modalService
                            .setResponse({...this.data, status})
                            .close();
                        this.subscription.unsubscribe();
                        this.loaderService.close();
                    })
                    .catch(() => {
                        this.loaderService.close();
                    });
            })
            .catch((e) => {
                this.loaderService.close();
                throw e;
            });
    }

    private async processAuth() {
        if (this.globalDataService.getItem('currentUser')) {
            return Promise.resolve();
        }
        if (this.data.event.simpleReg) {
            return new Promise((resolve, reject) => {
                const subscription: Subscription = this.modalService.open('simple-auth-check')
                    .subscribe((authenticated: boolean) => {
                        if (authenticated) {
                            resolve();
                        } else {
                            reject();
                        }
                        subscription.unsubscribe();
                    });
            });
        }
        return new Promise((resolve, reject) => {
            const subscription: Subscription = this.modalService.open('login-modal')
                .subscribe((data) => {
                    if (data === AuthResult.LOGIN) {
                        resolve();
                    } else {
                        reject();
                    }
                    subscription.unsubscribe();
                });
        });
    }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AdditionalRegFieldsInterface, FiasAddress} from '@app/_core/modals/login-modal/interfaces/reg-fields.interface';
import {IRequestObject} from '@app/models/shared/request-object.interface';
import {ApiService} from '@app/shared/api/api.service';

@Injectable({providedIn: 'root'})
export class DictionaryService {
    public socialNetworks$$: BehaviorSubject<{id: number; name: string}[]> = new BehaviorSubject<{id: number; name: string}[]>([
        {
            id: 1,
            name: 'Facebook'
        },
        {
            id: 2,
            name: 'Вконтакте'
        },
        {
            id: 3,
            name: 'Instagram'
        }
    ]);
    public occupations$$: BehaviorSubject<{id: number; name: string}[]> = new BehaviorSubject<{id: number; name: string}[]>([
        {
            id: 1,
            name: 'Специалист'
        },
        {
            id: 2,
            name: 'Руководитель'
        },
        {
            id: 3,
            name: 'Предприниматель'
        },
        {
            id: 4,
            name: 'Малый бизнес (1-5 работников)'
        },
        {
            id: 5,
            name: 'Независимый специалист'
        },
        {
            id: 6,
            name: 'Домохозяйка'
        },
        {
            id: 7,
            name: 'Студент'
        },
        {
            id: 8,
            name: 'Школьник'
        },
        {
            id: 9,
            name: 'Пенсионер'
        },
        {
            id: 10,
            name: 'Не работающая(-ий)'
        }
    ]);

    public dates$$: BehaviorSubject<{days: string[]; months: string[]; years: string[]}> = new BehaviorSubject<{days: string[]; months: string[]; years: string[]}>({
        days: this.generateCalendars(1, new Date().getDate()),
        months: this.generateCalendars(1, new Date().getMonth() + 1),
        years: this.generateYears(1920)
    });
    public citiesAndDistricts$$: BehaviorSubject<{cities: FiasAddress[], districts: FiasAddress[]}> = new BehaviorSubject<{cities: FiasAddress[], districts: FiasAddress[]}>(null);
    public interests$$: BehaviorSubject<{Id: string; Name: string}[]> = new BehaviorSubject<{Id: string; Name: string}[]>([]);
    constructor(private api: ApiService) {
    }

    public getInterests(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/dictionary/interests`
        };
        return this.api.get(requestObject).then((data) => this.interests$$.next(data));
    }

    public getRegions(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/dictionary/regions`
        };
        return this.api.get(requestObject).then((data: FiasAddress[]) => this.citiesAndDistricts$$.next({cities: data.filter((item: FiasAddress) => item.TypeCode === 1),
            districts: data.filter((item: FiasAddress) => item.TypeCode === 2)}));
    }

    public register(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/dictionary/register`
        };
        return this.api.get(requestObject).then((data) => this.interests$$.next(data));
    }

    private generateCalendars(from: number, to: number): string[] {
        const calendars: string[] = [];
        for (let i = from; i <= to; i++) {
            calendars.push(i <= 9 ? '0' + i : String(i));
        }
        return calendars;
    }

    private generateYears(from: number): string[] {
        const currentYear: number = new Date().getUTCFullYear();
        const currentYears: string[] = [];
        for (let i = from; i <= currentYear; i++) {
            currentYears.push(i <= 9 ? '0' + i : String(i));
        }
        return currentYears.reverse();
    }

}

import {NgModule} from "@angular/core";
import {PhoneInputComponent} from "@app/_core/components/phone-input/phone-input.component";
import {TextMaskModule} from "angular2-text-mask";

@NgModule({
    declarations: [PhoneInputComponent],
    imports: [
        TextMaskModule
    ],
    exports: [PhoneInputComponent]
})
export class PhoneInputModule {
    constructor() {
    }
}

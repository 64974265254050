import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';


import { ApiService } from '@app/shared/api/api.service';
import { GlobalDataService } from '@app/shared/global-data/global-data.service';
import {BasketSidebarService} from '@app/_core/components/basket-sidebar/services/basket-sidebar.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserResolver implements Resolve<Promise<any>> {

  constructor(private apiService: ApiService,
              private basketSidebarService: BasketSidebarService,
    private globalDataService: GlobalDataService) {
  }

  resolve(): Promise<any> {
    return this.apiService.getCurrentUser()
        .then((data: any) => {
            this.basketSidebarService.loadCurrentOrder();
            return Promise.resolve(data);
        })
        .catch(_ => {
          console.log('No current user');
          return Promise.resolve();
        });
  }
}

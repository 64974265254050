import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ModalService} from '@app/shared/modal-service/modal.service';
import {ScaleElementAnimation} from '@app/_core/animation/animations';

@Component({
    selector: 'app-events-list-modal',
    templateUrl: './events-list-modal.component.html',
    styleUrls: ['./events-list-modal.component.scss'],
    animations: [ScaleElementAnimation]
})
export class EventsListModalComponent implements OnInit {

    subscription: Subscription;

    data: any;

    constructor(private modalService: ModalService) {
    }

    ngOnInit() {
        this.subscription = this.modalService.getData()
            .subscribe((data: any) => {
                this.data = data;
            });
    }

    close(): void {
        this.modalService.close();
        this.subscription.unsubscribe();
    }

}

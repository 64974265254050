import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from '@app/content/components/loader/loader.service';

import {Observable, Subject, Subscription, timer} from 'rxjs';

import {ModalService} from '@app/shared/modal-service/modal.service';
import {ApiService} from '@app/shared/api/api.service';

import {ScaleElementAnimation} from '@app/_core/animation/animations';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {
    AdditionalRegFieldsInterface,
    FiasAddress,
    RegistrationForm
} from '@app/_core/modals/login-modal/interfaces/reg-fields.interface';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {DictionaryService} from '@app/_core/services/dictionary.service';
import {ufaCityId} from '../../../../environments/environment.prod';
import emailMask from 'text-mask-addons/dist/emailMask';
import {environment} from '../../../../environments/environment';
import {stringToDate} from '@app/_core/utils/stringToDate.util';
import {correctPhoneNumber} from '@app/_core/utils/correctifyPhoneNumber.utils';
import {correctifyDate} from "@app/_core/utils/correctifyDate.utils";
import {BasketSidebarService} from "@app/_core/components/basket-sidebar/services/basket-sidebar.service";
import {Router} from '@angular/router';

export enum AuthResult {
    LOGIN,
    RESTORE,
    REGISTER
}

@Component({
    selector: 'app-login-modal',
    templateUrl: './login-modal.component.html',
    styleUrls: ['./login-modal.component.scss'],
    animations: [ScaleElementAnimation]
})
export class LoginModalComponent implements OnInit, OnDestroy {

    public subscriptions: Subscription[] = [];
    public state = 'login'/*'smsVerification'*/;
    public passRecoveryState = 'phoneInput';
    public counter$: Observable<number>;
    public count = 300;
    public data: any = {};
    public registrationData: any = {
        interests: [''],
        children: [{
            birthDate: '',
            genderCode: ''
        }]
    };
    public passwordRecoveryData: any = {
        phone: '',
        isValid: false,
        token: '',
        temporaryPassword: '',
        newPassword: ''
    };

    public dateControl: FormControl = this._fb.control(null);

    public childrenFormArray: FormArray = this._fb.array([this._fb.group({
        birthDate: this._fb.control('', [this.kidsBirthdayValidator, Validators.pattern('^(0?[1-9]|[12][0-9]|3[01])[.](0?[1-9]|1[012])[.]\\d{4}$')]),
        genderCode: this._fb.control(''),
    })]);

    public occupations$!: Observable<{ id: number, name: string }[]>;
    public socialNetworks$!: Observable<{ id: number, name: string }[]>;
    public regions$: Observable<{ cities: FiasAddress[], districts: FiasAddress[] }> = this._dictionaryService.citiesAndDistricts$$.asObservable();
    public interests$: Observable<any[]> = this._dictionaryService.interests$$.asObservable();
    public unsubSubj$$: Subject<any> = new Subject<any>();
    public ufaCityId = ufaCityId;

    public loginForm: FormGroup = this._fb.group({
        username: this._fb.control('', [Validators.required, Validators.pattern('^[0-9]*$')]),
        password: this._fb.control('', Validators.required)
    });
    public cities: FiasAddress[] = [];
    public districts: FiasAddress[] = [];
    public extraFields!: AdditionalRegFieldsInterface;
    public isPhoneValid = true;
    public isBirthDateCorrect = true;
    public isResendConfirmActive = false;
    public dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
    public emailMask = emailMask;
    private registrationToken = '';

    waiting = false;

    constructor(
        private modalService: ModalService,
        private apiService: ApiService,
        private loaderService: LoaderService,
        private _dictionaryService: DictionaryService,
        private _fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private basketSidebarService: BasketSidebarService,
        private router: Router,
    ) {
        this.socialNetworks$ = this._dictionaryService.socialNetworks$$.asObservable();
        this.occupations$ = this._dictionaryService.occupations$$.asObservable();
    }

    public get loginUsername(): FormControl {
        return this.loginForm.get('username') as FormControl;
    }

    public get loginPassword(): FormControl {
        return this.loginForm.get('password') as FormControl;
    }

    ngOnInit() {
        const subscription: Subscription = this.modalService.getData()
            .subscribe((data: any) => {
                if (data) {
                    this.data = data;
                }
            });
        this.regions$
            .pipe(
                filter((data) => data !== null),
                takeUntil(this.unsubSubj$$)
            )
            .subscribe((data: { cities: FiasAddress[], districts: FiasAddress[] }) => {
                this.cities = data.cities;
                this.districts = data.districts;
            });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy() {
        this.unsubSubj$$.next();
        this.unsubSubj$$.complete();
    }

    close(status: AuthResult): void {
        this.modalService
            .setResponse(status)
            .close();
    }

    public async loadData(): Promise<any> {
        if (!this._dictionaryService.citiesAndDistricts$$.value) {
            await this._dictionaryService.getRegions();
        }
        if (!this._dictionaryService.interests$$.value.length) {
            await this._dictionaryService.getInterests();
        }
    }

    public onInterestSelected(event, index): void {
        this.registrationData.interests[index] = event.target.value;
    }

    public onCitySelected(): void {
        this.registrationData.district = undefined;
    }

    public onNumberChange(phoneNumberInfo: { phone: string, valid: boolean, formattedPhone: string }): void {
        this.loginUsername.setValue(phoneNumberInfo.phone);
    }

    public onPassRecoveryPhoneChange(phoneNumberInfo: { phone: string, valid: boolean, formattedPhone: string }): void {
        this.passwordRecoveryData.isValid = phoneNumberInfo.valid;
        if (this.passwordRecoveryData.phone) {
            this.passwordRecoveryData = {...this.passwordRecoveryData, phone: phoneNumberInfo.formattedPhone};
        } else {
            this.passwordRecoveryData.phone = phoneNumberInfo.formattedPhone;
        }
    }

    public onPhoneNumberChange(phoneNumberInfo: { phone: string, valid: boolean, formattedPhone: string }): void {
        this.isPhoneValid = phoneNumberInfo.valid;
        if (this.registrationData.phone) {
            this.registrationData = {...this.registrationData, phone: phoneNumberInfo.formattedPhone};
        } else {
            this.registrationData.phone = phoneNumberInfo.formattedPhone;
        }
    }

    public addKid(): void {
        if (this.childrenFormArray.controls.length < 5) {
            this.childrenFormArray.push(this._fb.group({
                birthDate: this._fb.control('', [this.kidsBirthdayValidator,
                    Validators.pattern('^(0?[1-9]|[12][0-9]|3[01])[.](0?[1-9]|1[012])[.]\\d{4}$')]),
                genderCode: this._fb.control(''),
            }));
        }
    }

    public deleteKid(index: number): void {
        this.childrenFormArray.removeAt(index);
    }

    public addInterest(): void {
        if (this.registrationData.interests.length < 5) {
            this.registrationData.interests.push('');
        }
    }

    public deleteInterest(index: number): void {
        this.registrationData.interests.splice(index, 1);
    }

    public resendConfirmRegistration(): void {
        this.loaderService.open({min: environment.preloaderDelay});
        this.apiService.resendConfirmRegistration({
            token: this.registrationToken,
        })
            .then((response) => {
                this.registrationToken = response;
                this.isResendConfirmActive = false;
            })
            .finally(() => this.loaderService.close());
    }

    public confirmRegistration(): void {
        this.loaderService.open({min: environment.preloaderDelay});
        this.apiService.registrationFinish({
            ...this.extraFields,
            code: this.data.smsCode,
            token: this.registrationToken,
        }).then((response) => {
            this.registrationToken = '';
            this.close(AuthResult.REGISTER);
            const modalSubscription: Subscription = this.modalService.open('warning-modal', {
                message: response.hintMessage || 'Регистрация прошла успешно!'
            }).subscribe(() => {
                    modalSubscription.unsubscribe();
                });
        }).catch((e) => {
            if(e.error.errorCode === '101024' || e.error.errorCode === '101022') {
                this.isResendConfirmActive = true;
            } else {
                const modalSubscription: Subscription = this.modalService.open('warning-modal', {
                    message: e.error.errorMessage
                })
                    .subscribe(() => {
                        modalSubscription.unsubscribe();
                    });
            }
        })
          .finally(() => this.loaderService.close());
    }

    login() {
        document.location.replace(`https://devweb.getseat.ru/webapi/oauth/init`);
    }

    restore() {
        this.loaderService.open({min: environment.preloaderDelay});
        this.apiService.restorePassword({
            phoneOrEmail: correctPhoneNumber(this.passwordRecoveryData.phone)
        })
            .then((response) => {
                this.passwordRecoveryData.token = response;
                this.passRecoveryState = 'temporaryPassword';
                this.startTimer();
            })
            .finally(() => this.loaderService.close());
    }

    codeConfirm() {
        this.loaderService.open({min: environment.preloaderDelay});
        this.apiService.validatePassword({
            token: this.passwordRecoveryData.token,
            temporaryPassword: this.passwordRecoveryData.temporaryPassword
        })
            .then((response) => {
                this.passwordRecoveryData.token = response;
                this.passRecoveryState = 'newPassword';
            })
            .finally(() => this.loaderService.close());
    }

    resendCode() {
        this.loaderService.open({min: environment.preloaderDelay});
        this.apiService.validatePasswordResend({
            token: this.passwordRecoveryData.token,
        })
            .then((response) => {
                this.count = 300;
                this.startTimer();
                this.passwordRecoveryData.token = response;
            })
            .finally(() => this.loaderService.close());
    }

    sendNewPassword() {
        this.loaderService.open({min: environment.preloaderDelay});
        this.apiService.changePasswordFinish({
            token: this.passwordRecoveryData.token,
            password: this.passwordRecoveryData.newPassword
        })
            .then((response) => {
                this.close(AuthResult.RESTORE);
                const modalSubscription: Subscription = this.modalService.open('warning-modal', {
                    message: 'Пароль успешно изменён'
                })
                    .subscribe(() => {
                        modalSubscription.unsubscribe();
                    });
            })
            .finally(() => this.loaderService.close());
    }

    async registration() {
        const currentDate: Date = new Date();
        if (currentDate < stringToDate(this.registrationData.birthDate, '.')) {
            this.isBirthDateCorrect = false;
        } else {
            this.extraFields = JSON.parse(JSON.stringify({
                occupation: this.registrationData.occupation,
                interests: this.registrationData.interests.filter((item) => item !== ''),
                children: this.childrenFormArray.value.filter((item) => item.birthDate !== '' && item.genderCode !== '').map((kid) => {
                    return {
                        ...kid,
                        birthDate: correctifyDate(kid.birthDate)
                    };
                }),
                region: this.registrationData.district ? this.registrationData.district : this.registrationData.city,
                email: this.registrationData.email
            }));
            this.loaderService.open({min: environment.preloaderDelay});
            const token = await this.apiService.registration({
                email: this.registrationData.email,
                firstName: this.registrationData.firstName,
                lastName: this.registrationData.lastName,
                birthDate: correctifyDate(this.registrationData.birthDate),
                middleName: this.registrationData.middleName,
                password: this.registrationData.password,
                phone: correctPhoneNumber(this.registrationData.phone),
                gender: this.registrationData.gender,
                socialId: this.registrationData.socialId,
                socialType: this.registrationData.socialType,
            })

            this.state = 'smsVerification';
            this.registrationToken = token;
            this.registrationData = {
                interests: [''],
                children: [{
                    birthDate: '',
                    genderCode: ''
                }]
            };
            this.loaderService.close();
        }
    }

    public async toRegistration(): Promise<void> {
        // 
        window.location.href = 'https://oauth-amur.getseat.ru/registration';
        // await this.router.navigateByUrl(
        //     'https://oauth-amur.getseat.ru/registration',
        //     {queryParamsHandling: 'merge'}
        // );
        this.loaderService.close();
    }

    public async toForgot(): Promise<void> {
        window.location.href = 'https://oauth-amur.getseat.ru/password-recovery';
        this.loaderService.close();
    }

    private startTimer() {
        this.counter$ = timer(0, 1000).pipe(
            take(this.count),
            map(() => --this.count)
        );
    }

    private kidsBirthdayValidator(control: AbstractControl): ValidationErrors {
        if (!control.value) {
            return null;
        } else {
            const parsedDate: Date = stringToDate(control.value, '.');
            if (parsedDate === new Date('Invalid Date')) {
                return {dateError: 'incorrect date format'};
            } else {
                if (parsedDate > new Date()) {
                    return {dateError: 'incorrect date format'};
                }
                return null;
            }
        }
    }
}

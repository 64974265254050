import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loyality',
  templateUrl: './loyality.component.html',
  styleUrls: ['./loyality.component.scss']
})
export class LoyalityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

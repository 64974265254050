// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
//  apiUrl: `https://tickets.stage.hawk.ru`,
  apiUrl: ``,
  paymentKey: 'pk_18907767dfad5f845fc28dd8ab22e',
  enablePreloader: true,
  preloaderDelay: 1000,
  url: 'https://devweb.getseat.ru',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import {ModalService} from '@app/shared/modal-service/modal.service';

import {environment} from '../../../environments/environment';

import {Observable, Subscription, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {GlobalDataService} from '@app/shared/global-data/global-data.service';

import {IRequestObject} from '@app/models/shared/request-object.interface';
import {AdditionalRegFieldsInterface} from "@app/_core/modals/login-modal/interfaces/reg-fields.interface";
import {MatSnackBar} from '@angular/material/snack-bar';
import {LoaderService} from "@app/content/components/loader/loader.service";

declare let screen;

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private TAG = 'HTTP SERVICE: ';

    private apiUrl = environment.apiUrl;
    private apiNew = '';

    constructor(
        private globalDataService: GlobalDataService,
        private http: HttpClient,
        private modalService: ModalService,
        private matSnackBar: MatSnackBar,
        private loaderService: LoaderService
    ) {
    }

    openPopupCenter(url, title, w, h): any {
        let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
        let dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
        let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        let left = ((width / 2) - (w / 2)) + dualScreenLeft;
        let top = ((height / 2) - (h / 2)) + dualScreenTop;
        let newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

        if (window.focus) {
            newWindow.focus();
        }
        return newWindow;
    }

    /**
     * Обработчик ошибок
     */
    errorHandler(error: HttpErrorResponse, handleError = true) {
        switch (error.status) {
            case 401: {
                localStorage.removeItem('currentUser');
                localStorage.removeItem('currentUserQuantity');
                this.globalDataService.removeItem('currentUser');
                this.globalDataService.removeItem('currentUserQuantity');
                if (handleError) {
                    const modalSubscription: Subscription = this.modalService.open('login-modal')
                        .subscribe((data) => {
                            modalSubscription.unsubscribe();
                        });
                }
                if (error.url.includes('login')) {
                    const modalSubscription: Subscription = this.modalService.open('warning-modal', {
                        message: 'Произошла ошибка авторизации'
                    })
                        .subscribe(() => {
                            modalSubscription.unsubscribe();
                        });
                }
                break;
            }
            case 301: {
                console.log(error)
            }
            case 504: {
                localStorage.removeItem('currentUser');
                localStorage.removeItem('currentUserQuantity');
                this.globalDataService.removeItem('currentUser');
                this.globalDataService.removeItem('currentUserQuantity');
                break;
            }
            default: {
                const showError = (parsedError) => {
                    const errorMessage = parsedError ? parsedError.message || parsedError.errorMessage : null;

                    const modalSubscription: Subscription = this.modalService.open('warning-modal', {
                        message: errorMessage || 'Произошла неизвестная ошибка'
                    })
                        .subscribe(() => {
                            modalSubscription.unsubscribe();
                        });
                };

                let parsedError;

                if (error.error instanceof Blob) {
                    const fr = new FileReader();
                    fr.onload = function (evt) {
                        const target: any = evt.currentTarget;
                        parsedError = target ? JSON.parse(target.result) : null;
                        showError(parsedError);
                    };
                    fr.readAsText(error.error);
                    return;
                } else {
                    parsedError = typeof error.error === 'string' ? JSON.parse(error.error) : error.error;
                }
                if (handleError) {
                    showError(parsedError);
                }
                break;
            }
        }
    }

    /**
     * Method: GET
     */
    get(requestObject: IRequestObject, withoutApiUrl: boolean = false, isText: boolean = false, withoutHandler: boolean = false, isBlob: boolean = false, isNewApi = false): Promise<any> {
        let requestParams: object;
        if (isText) {
            requestParams = {
                responseType: 'text'
            };
        }

        if (isBlob) {
            requestParams = {
                responseType: 'blob'
            };
        }

        return this.http
            .get(`${withoutApiUrl ? '' : isNewApi ? this.apiNew : this.apiUrl}${requestObject.urlAdd}`, requestParams)
            .pipe(
                map((results: Response) => results),
                catchError((error: HttpErrorResponse) => {
                    if (!withoutHandler) {
                        this.errorHandler(error);
                    }
                    return throwError(new Error(`${this.TAG} Error getting ${error.url}: ${error.status} => ${error.message}`));
                })
            ).toPromise();
    }

    /**
     * Method: POST
     */
    post(requestObject: IRequestObject, body: any, withoutApiUrl: boolean = false, isText: boolean = false, isBlob: boolean = false, isNewApi = false, handleError = true): Promise<any> {
        let requestParams: object;
        if (isText) {
            requestParams = {
                responseType: 'text'
            };
        }

        if (isBlob) {
            requestParams = {
                responseType: 'blob'
            };
        }

        return this.http
            .post(`${withoutApiUrl ? '' : isNewApi ? this.apiNew : this.apiUrl}${requestObject.urlAdd}`, body, requestParams)
            .pipe(
                map((results: Response) => results),
                catchError((error: HttpErrorResponse) => {
                    this.errorHandler(error, handleError);
                    return throwError(error);
                    //return throwError(new Error(`${this.TAG} Error getting ${error.url}: ${error.status} => ${error.message} ${error.error.errorCode ? 'with ERROR_CODE=' + error.error.errorCode : ''}`));
                })
            ).toPromise();
    }

    /**
     * Method: PUT
     */
    put(requestObject: IRequestObject, body: any, withoutApiUrl: boolean = false, isText: boolean = false): Promise<any> {
        let requestParams: object;
        if (isText) {
            requestParams = {
                responseType: 'text'
            };
        }
        return this.http
            .put(`${withoutApiUrl ? '' : this.apiUrl}${requestObject.urlAdd}`, body, requestParams)
            .pipe(
                map((results: Response) => results),
                catchError((error: HttpErrorResponse) => {
                    this.errorHandler(error);
                    return throwError(new Error(`${this.TAG} Error getting ${error.url}: ${error.status} => ${error.message}`));
                })
            ).toPromise();
    }

    /**
     * Method: PUT
     */
    delete(requestObject: IRequestObject): Promise<any> {
        return this.http
            .delete(`${this.apiUrl}${requestObject.urlAdd}`)
            .pipe(
                map((results: Response) => results),
                catchError((error: HttpErrorResponse) => {
                    this.errorHandler(error);
                    return throwError(new Error(`${this.TAG} Error getting ${error.url}: ${error.status} => ${error.message}`));
                })
            ).toPromise();
    }

    getCurrentUser(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/auth/me'
        };
        const request: Promise<any> = this.get(requestObject, false, false, false);

        request
            .then((data: any) => {
                localStorage.setItem('currentUser', data);
                this.globalDataService.setItem('currentUser', data);
            })
            .catch(() => {
                this.globalDataService.setItem('currentUserQuantity', 0);
            });

        return request;
    }

    logIn(): void {
        window.open(`${this.apiUrl}/webapi/auth/login`, '_self');
    }

    logInAuth0(): Promise<any> {
        return new Promise((resolve) => {
            const loginWindow = this.openPopupCenter(`${this.apiUrl}/webapi/auth/login`, 'Авторизация', 600, 600);
            const interval = setInterval(() => {
                if (loginWindow && loginWindow.closed) {
                    clearInterval(interval);
                    resolve();
                }
            }, 500);
        });
    }

    logOut(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/auth/logout'
        };
        const request: Promise<any> = this.get(requestObject);

        request
            .then((data: any) => {
                this.globalDataService.setItem('currentUser', null);
            });

        return request;
    }

    logInModal(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/auth/login'
        };
        return this.post(requestObject, model, false, false, false, true);
    }

    restorePassword(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/client-password-recover/request'
        };

        return this.put(requestObject, model, false, true);
    }

    updatePhone(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/client-phone-update/request'
        };

        return this.post(requestObject, model, false, true);
    }

    updatePhoneSMSConfirm(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/client-phone-update/confirm'
        };

        return this.put(requestObject, model);
    }

    updateEmail(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/client-email-update/request'
        };

        return this.post(requestObject, model);
    }

    validatePassword(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/client-password-recover/validate'
        };

        return this.post(requestObject, model, false, true);
    }

    validatePasswordResend(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/client-password-recover/reactivate-request'
        };

        return this.post(requestObject, model, false, true);
    }

    changePasswordFinish(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/client-password-recover/finish'
        };

        return this.post(requestObject, model);
    }

    registration(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/register/request'
        };

        return this.post(requestObject, model, false, true);
    }

    registrationV2(model): Observable<any> {
        return this.http.post('/webapi/register/request', model).pipe(
            catchError((error) => {
                const parsedError = typeof error.error === 'string' ? JSON.parse(error.error) : error.error;
                const errorMessage = parsedError ? parsedError.message || parsedError.errorMessage : null;
                this.matSnackBar.open(`Ошибка регистрации:( Причина: ${errorMessage}`, 'Закрыть', {duration: 5000, verticalPosition: 'top'});
                this.loaderService.close();

                return throwError('registration error ');
            })
        );
    }

    registrationFinish(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/register/finish'
        };

        return this.post(requestObject, model, false, false, false, false, false);
    }

    resendConfirmRegistration(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/register/resend-sms'
        };

        return this.post(requestObject, model, false, true);
    }

    getLkUserInfo() {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/client-profile'
        };

        return this.get(requestObject);
    }

    saveLkUserInfo(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/client-profile'
        };

        return this.put(requestObject, model);
    }

    passwordUpdate(model) {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/client-password-update'
        };

        return this.put(requestObject, model).then(() => {
            const modalSubscription: Subscription = this.modalService.open('warning-modal', {
                message: 'Пароль успешно изменён'
            })
                .subscribe(() => {
                    modalSubscription.unsubscribe();
                });
        });
    }

    lkLogout(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/auth/logout'
        };
        const request: Promise<any> = this.post(requestObject, {});

        request
            .then((data: any) => {
                localStorage.removeItem('currentUser');
                localStorage.removeItem('currentUserQuantity');
                this.globalDataService.removeItem('currentUser');
                this.globalDataService.removeItem('currentUserQuantity');
            });

        return request;
    }

    getFooter(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: '/proxy/block/?name=footer'
        };

        return this.get(requestObject, false, true);
    }

    getEventsList(model: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/calendars/available/list'
        };

        return this.post(requestObject, model);
    }
    /*    requestObject: IRequestObject, withoutApiUrl: boolean = false, isText: boolean = false, withoutHandler: boolean = false, isBlob: boolean = false, isNewApi = false*/
    getEventsListGrouped(model: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/calendars/available/list/grouped'
        };

        return this.post(requestObject, model);
    }

    getEventsListGroupedNew(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/event-session/on-show/all'
        };

        return this.get(requestObject, false, false, false, false, true);
    }

    getAllEventsList(model: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/calendars/all/list'
        };

        return this.post(requestObject, model);
    }

    getEventById(id: number, model: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/calendars/${id}`
        };

        return this.post(requestObject, model);
    }

    getEventByIdNew(id: string): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/event-session/on-show/by-id/${id}`
        };

        return this.get(requestObject, false, false, false, false, true);
    }

    getEventSchemaById(id: string): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/event-session/svg-scheme/by-id/${id}`
        };

        return this.get(requestObject, false, true, false, false, true);
    }

    getSectorsOnSaleById(id: string, model: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/event-session/location-scheme-seats-outgroup/available/by-id/${id}`
        };

        return this.get(requestObject, false, false, false, false, true);
    }

    getPlacesSchemaByIds(calendarId: number, sectorId: number, seasonIds: number[]): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/seats/schema/${calendarId}/${sectorId}/build?${seasonIds.map(seasonId => `seasonIds=${seasonId}`).join('&')}`
        };

        return this.get(requestObject);
    }

    getTrashList(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/seats/reserved/list`
        };

        return this.get(requestObject);
    }

    reservePlace(calendarId: number, model: any): Promise<any> {
        return this.http
            .put(`${this.apiUrl}/webapi/seats/${calendarId}/reserve`, model, {observe: 'response'})
            .pipe(
                map((results: HttpResponse<any>) => results),
                catchError((error: HttpErrorResponse) => {
                    if (error.status !== 401) {
                        this.errorHandler(error);
                    }
                    return throwError(error);
                })
            ).toPromise();
    }

    unreservePlace(calendarId: number, model: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/seats/${calendarId}/unreserve`
        };

        return this.put(requestObject, model);
    }

    createOrder(clientId: string, withChild: boolean): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/orders/create?gaClientId=${clientId}&withChild=${withChild}`
        };

        return this.post(requestObject, null);
    }

    getSeatsByOrderId(id: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order/${id}/info`
        };

        return this.get(requestObject);
    }

    sendPromocode(id: any, model: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/orders/${id}/promocode`
        };

        return this.put(requestObject, model);
    }

    getCurrentQuantity(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/orders/unpaid/quantity`
        };

        return this.get(requestObject);
    }

    modifySeatStatus(status: boolean, seatId: number, calendarId: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/seats/${calendarId}/modify/${seatId}/${status ? 'adult' : 'child'}`
        };

        return this.put(requestObject, {});
    }

    getOrderList(body: any = {}): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/orders/list'
        };

        return this.post(requestObject, body);
    }

    getOrderListNew(): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/order/all/my'
        };

        return this.get(requestObject, false, false, true);
    }

    deleteOrder(orderUuid: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order/${orderUuid}/cancel`
        };

        return this.delete(requestObject);
    }

    printOrder(orderId: number): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/ticket/pdf/print/order/${orderId}`
        };

        return this.post(requestObject, {}, false, false, true);
    }

    printTicket(ticketId: number): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/ticket/pdf/print/ticket/${ticketId}`
        };

        return this.post(requestObject, {}, false, false, true);
    }

    sendOrder(orderId: number): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/ticket/pdf/email/order/${orderId}`
        };

        return this.post(requestObject, {}, false, false, true);
    }

    sendTicket(ticketId: number): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/ticket/pdf/email/ticket/${ticketId}`
        };

        return this.post(requestObject, {}, false, false, true);
    }

    sendWallet(ticketId: number): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/tickets/wallet/ticket/${ticketId}`
        };

        const requestParams: any = {
            observe: 'response',
            responseType: 'blob'
        };

        return this.http
            .get(`${this.apiUrl}${requestObject.urlAdd}`, requestParams).toPromise();
    }

    sendVipRequest(data: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/viplounge/request`
        };

        return this.post(requestObject, data);
    }

    sendHelpRequest(data: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/help/request`
        };

        return this.post(requestObject, data);
    }

    sendFanRequest(data: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/awaygames/fans/request`
        };

        return this.post(requestObject, data);
    }

    receiveFreeOrder(orderId: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/orders/${orderId}/receive`
        };

        return this.put(requestObject, null);
    }

    processPayment(orderId: any, model: any): Promise<any> {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/orders/${orderId}/payment/process`
        };

        return this.put(requestObject, model);
    }

    getBannerParams() {
        const requestObject: IRequestObject = {
            urlAdd: '/webapi/banner/params'
        };
        return this.get(requestObject, false, false, true);
    }

    getTicketEventsList(calendarId: number, seatId: number) {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/calendars/${calendarId}/orderseat/${seatId}`
        };
        return this.get(requestObject);
    }

    getOrderBonusesAmount(orderId: number) {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/orders/${orderId}/bonuses`
        };
        return this.get(requestObject);
    }

    payBonuses(orderId: number, model: any) {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/orders/${orderId}/bonuses`,
        };

        return this.put(requestObject, model);
    }

    payDigift(orderId: number, model: any) {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/orders/${orderId}/digift`,
        };

        return this.put(requestObject, model);
    }

    createLounge<T>(calendarId: number, sectorId: number, quantity: number) {
        return this.http.post<T>(`${this.apiUrl}/webapi/orders/create-lounge`, {
            calendarId,
            sectorId,
            quantity
        }, {observe: 'response'}).toPromise();
    }

    getLoungeInfo(calendarId: number, sectorId: number) {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/seats/schema/${calendarId}/${sectorId}/lounge`
        };
        return this.get(requestObject);
    }

    getOrderMerch(orderId: number) {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order-merch/${orderId}/basket`
        };
        return this.get(requestObject);
    }

    getOrderMerchLimits(orderId: number) {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order-merch/${orderId}/limits`
        };
        return this.get(requestObject);
    }

    addMerchProduct(orderId: number, id: string, quantity: number) {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order-merch/${orderId}`,
        };

        return this.put(requestObject, {id, quantity});
    }

    removeMerchProduct(orderId: number, merchId: string) {
        const requestObject: IRequestObject = {
            urlAdd: `/webapi/order-merch/${orderId}?merchId=${merchId}`
        };
        return this.delete(requestObject);
    }

    simpleAuthCheck<T>(phone: string) {
        const payload = new HttpParams()
            .set('phone', phone);

        return this.http.post<T>(`${this.apiUrl}/webapi/simple-auth/check`, payload).toPromise();
    }

    simpleAuth(phone: string, newEmail: string = null, smsCode: string = null) {
        let payload = new HttpParams()
            .set('phone', phone);

        if (newEmail !== null) {
            payload = payload.set('newEmail', newEmail);
        }

        if (smsCode !== null) {
            payload = payload.set('smsCode', smsCode);
        }

        return this.http.post(`${this.apiUrl}/webapi/simple-auth/login`, payload).toPromise();
    }

    simpleLogout() {
        return this.post({
            urlAdd: `/webapi/simple-auth/logout`
        }, null);
    }

    simpleReg(model) {
        return this.http.post(`${this.apiUrl}/webapi/simple-auth/register-confirm`, model).toPromise();
    }

    simpleEmailChange(phone: string, email: string) {
        const payload = new HttpParams()
            .set('phone', phone)
            .set('newEmail', email);

        return this.http.post(`${this.apiUrl}/webapi/simple-auth/email-change`, payload).toPromise();
    }

    getPersonalInfo<T>() {
        return this.http.get<T>(`${this.apiUrl}/webapi/auth/me/person`).toPromise();
    }

    orderPayCredit<T>(orderId: number): Promise<T> {
        return this.put({
            urlAdd: `/webapi/orders/${orderId}/pay-credit`
        }, null);
    }
}

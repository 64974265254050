import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'searchQuestion'})
export class SearchQuestionPipe implements PipeTransform {
  transform(questions: any[], params: string) {
    if (!params) {
      return questions;
    } else {
      return questions
        .filter(question => question.question.toLowerCase().indexOf(params.toLowerCase()) > -1);
    }
  }
}

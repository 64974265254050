import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ApiService} from '@app/shared/api/api.service';
import {GlobalDataService} from '@app/shared/global-data/global-data.service';
import {ModalService} from '@app/shared/modal-service/modal.service';

import {collapseToggleAnimation} from '@app/_core/animation/animations';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    animations: [collapseToggleAnimation]
})
export class HeaderComponent implements OnInit {

    isMenuOpened = false;

    constructor(
        private globalDataService: GlobalDataService,
        private apiService: ApiService,
        private router: Router,
        private modalService: ModalService
    ) {
    }

    ngOnInit() {

    }

    toggleBurgerMenu(): void {
        const body: HTMLBodyElement = document.body as HTMLBodyElement;
        // !this.isMenuOpened ? body.classList.add('scroll-lock') : body.classList.remove('scroll-lock');
        !this.isMenuOpened ? this.modalService.disableScroll() : this.modalService.enableScroll();
        this.isMenuOpened = !this.isMenuOpened;
    }

    onResize(): void {
        if (window.innerWidth >= 979) {
            this.isMenuOpened = false;
            const body: HTMLBodyElement = document.body as HTMLBodyElement;
            body.classList.remove('scroll-lock');
        }
    }

    getCurrentUser() {
        return this.globalDataService.getItem('currentUser');
    }

    getCurrentUserQuantity() {
        return this.globalDataService.getItem('currentUserQuantity');
    }

    logIn() {
        const modalSubscription: Subscription = this.modalService.open('login-modal')
            .subscribe((data) => {
                modalSubscription.unsubscribe();
            });
    }

    goToProfile() {
        this.router.navigate(['/lk']);
        /*    const protocol = location.protocol;
            const slashes = protocol.concat("//");
            const host = slashes.concat(window.location.hostname);
            window.open(this.globalDataService.getItem('mainSiteMy') + '/user/?next=' + host, '_self');*/
    }

    goToOrderList() {
        if (this.getCurrentUser()) {
            this.router.navigate(['/order-list'], {queryParamsHandling: 'merge'})
                .then(() => this.isMenuOpened && this.toggleBurgerMenu());
        } else {
            this.logIn();
        }
    }

    navigate(url: string) {
        const commands = url === '/' ? [[url]] : [[url], {queryParamsHandling: 'merge'}];
        this.router.navigate.apply(this.router, commands)
            .then(() => this.toggleBurgerMenu());
    }

    openUrl(url: string) {
        window.open(url, '_blank');
    }

    checkCurrentUrl(url: string): boolean {
        return this.router.url === url;
    }

}

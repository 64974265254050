import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {ContainerComponent} from '@app/content/containers/container/container.component';
import {CurrentUserResolver} from '@app/shared/resolvers/current-user/current-user.resolver';
import {NotFoundComponent} from '@app/content/containers/not-found/not-found.component';
import {AccessGuard} from '@app/_core/guards/access.guard';
import {UpdatePhoneComponent} from '@app/content/components/update-phone/update-phone.component';
import {UpdateEmailComponent} from './content/components/update-email/update-email.component';
import {OfertaComponent} from '@app/content/containers/oferta/oferta.component';
import {AgreementComponent} from '@app/content/containers/agreement/agreement.component';
import {LoyalityComponent} from '@app/content/containers/loyality/loyality.component';   
import {ImpressionComponent} from '@app/content/containers/impression/impression.component';
import {LoyalitystComponent} from '@app/content/containers/loyalityst/loyalityst.component';


const routes: Routes = [
    {
        path: '',
        component: ContainerComponent,
        children: [
            {
                path: '',
                loadChildren: './content/containers/home/home.module#HomeModule',
                data: {
                    title: 'Билеты ХК Амур'
                }
            },
            {
                path: 'subscriptions',
                loadChildren: './content/containers/subscription/subscription.module#SubscriptionModule',
                data: {
                    title: 'Абонементы | ХК Амур'
                }
            },
            {
                path: 'update-phone',
                component: UpdatePhoneComponent,
                canActivate: [AccessGuard],
                data: {
                    title: 'Смена телефона | ХК Амур'
                }
            },
            {
                path: 'client-email-update-change',
                component: UpdateEmailComponent,
                canActivate: [AccessGuard],
                data: {
                    title: 'Смена почты | ХК Амур'
                }
            },
            {
                path: 'client-email-update-success',
                component: UpdateEmailComponent,
                data: {
                    title: 'Смена почты | ХК Амур'
                }
            },
            {
                path: 'client-email-update-expired',
                component: UpdateEmailComponent,
                data: {
                    title: 'Смена почты | ХК Амур'
                }
            },
            {
                path: 'client-email-update-error',
                component: UpdateEmailComponent,
                data: {
                    title: 'Смена почты | ХК Амур'
                }
            },
//            {
//                path: 'lodge/:calendarId/:sectorId',
//                loadChildren: './content/containers/lodge/lodge.module#LodgeModule',
//                data: {
//                    title: 'VIP Ложи | ХК Амур'
//                }
//            },
            {
                path: 'lk',
                loadChildren: './content/containers/lk/lk.module#LkModule',
                canActivate: [AccessGuard],
                data: {
                    title: 'Личный кабинет | ХК Амур'
                }
            },
            {
                path: 'subscription-events',
                loadChildren: './content/containers/subscription-events/subscription-events.module#SubscriptionEventsModule',
                data: {
                    title: 'Абонементы | ХК Амур'
                }
            },
            {
                path: 'oferta',
                component: OfertaComponent,
                data: {
                    title: 'Оферта | ХК Амур'
                }
            },
            {
                path: 'impression',
                component: ImpressionComponent,
                data: {
                    title: 'Витрина впечатлений | ХК Амур'
                }
            },
            {
                path: 'loyalityst',
                component: LoyalitystComponent,
                data: {
                    title: 'Программа лояльности | ХК Амур'
                }
            },
            {
                path: 'agreement',
                component: AgreementComponent,
                data: {
                    title: 'Согласие | ХК Амур'
                }
            },
            {
                path: 'loyality',
                component: LoyalityComponent,
                data: {
                    title: 'Лояльность | ХК Амур'
                }
            },
//            {
//                path: 'vip',
//                loadChildren: './content/containers/vip/vip.module#VipModule',
//                data: {
//                    title: 'VIP ложи | ХК Амур'
//                }
//            },
//            {
//                path: 'arena',
//                loadChildren: './content/containers/arena/arena.module#ArenaModule',
//                data: {
//                    title: 'Арена | ХК Амур'
//                }
//            },
//            {
//                path: 'away-games',
//                loadChildren: './content/containers/away-games/away-games.module#AwayGamesModule',
//                data: {
//                    title: 'Выездные игры | ХК Амур'
//                }
//            },
//            {
//                path: 'help',
//                loadChildren: './content/containers/help/help.module#HelpModule',
//                data: {
//                    title: 'Помощь | ХК Амур'
//                }
//            },
            {
                path: 'order-list',
                loadChildren: './content/containers/order-list/order-list.module#OrderListModule',
                canActivate: [AccessGuard],
                data: {
                    title: 'Список заказов | ХК Амур'
                }
            },
            {
                path: 'sectors',
                loadChildren: './content/containers/sectors/sectors.module#SectorsModule',
                data: {
                    title: 'Выбор сектора | ХК Амур'
                }
            },
            {
                path: 'places',
                loadChildren: './content/containers/places/places.module#PlacesModule',
                data: {
                    title: 'Выбор места | ХК Амур'
                }
            },
            {
                path: 'basket',
                loadChildren: './content/containers/basket/basket.module#BasketModule',
                data: {
                    title: 'Корзина | ХК Амур'
                },
            },
            {
                path: 'payment/:orderId',
                loadChildren: './content/containers/payment-result/payment-result.module#PaymentResultModule',
                data: {
                    title: 'Результат оплаты | ХК Амур'
                }
            },
        ],
        resolve: {
            currentUser: CurrentUserResolver
        }
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: false})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

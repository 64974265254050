import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { Subscription } from 'rxjs';

import { ScaleElementAnimation } from '@app/_core/animation/animations';

import { ModalService } from '@app/shared/modal-service/modal.service';

@Component({
  selector: 'app-limited-view-modal',
  templateUrl: './limited-view-modal.component.html',
  styleUrls: ['./limited-view-modal.component.scss'],
  animations: [ScaleElementAnimation]
})
export class LimitedViewModalComponent implements OnInit {

  subscription: Subscription;

  data: any;

  constructor(private formBuilder: FormBuilder,
              private modalService: ModalService) {
  }

  ngOnInit() {
    this.subscription = this.modalService.getData()
      .subscribe((data: any) => {
        this.data = data;
      });
  }

  close(status: boolean): void {
    this.modalService
      .setResponse({...this.data, status: status})
      .close();
    this.subscription.unsubscribe();
  }

}

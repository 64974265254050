import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitTime'
})
export class SplitTimePipe implements PipeTransform {

  times = {
    hour: 3600,
    minute: 60,
    second: 1
  };

  transform(seconds: number, ...args): any {
    let formattedTimeString: string = '';
    for(const key in this.times) {
      if (!this.times.hasOwnProperty(key)) {
        continue;
      }
      if (Math.floor(seconds / this.times[key]) >= 0) {
        let timeShit: any = Math.floor(seconds / this.times[key]).toString();
        if (timeShit.length === 1) {
          timeShit = '0' + timeShit;
        }
        if (timeShit.length === 0) {
          timeShit = '00' + timeShit;
        }
        formattedTimeString += timeShit + ' ' + ':' + ' ';
        seconds = seconds - this.times[key] * Math.floor(seconds / this.times[key]);
      }
    }
    return formattedTimeString.slice(0, -2);
  }
}

import {Component} from '@angular/core';
import {ScaleElementAnimation} from '@app/_core/animation/animations';
import {ModalService} from '@app/shared/modal-service/modal.service';
import {ApiService} from '@app/shared/api/api.service';
import {LoaderService} from '@app/content/components/loader/loader.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpParams} from '@angular/common/http';

interface SimpleAuthResponse {
    email: string;
    phoneConfirmed: boolean;
    emailConfirmed: boolean;
}

@Component({
    selector: 'app-simple-auth-check',
    templateUrl: './simple-auth-check.component.html',
    styleUrls: ['./simple-auth-check.component.scss'],
    animations: [ScaleElementAnimation]
})
export class SimpleAuthCheckComponent {

    waiting = false;

    authForm: FormGroup;
    changeEmailForm: FormGroup;
    registerForm: FormGroup;
    confirmSMSForm: FormGroup;

    state: 'AUTH' | 'USER_INFO' | 'REGISTER' | 'CHANGE_EMAIL' | 'SMS_CONFIRM' = 'AUTH';

    authError: string;
    maskedEmail: string;
    changeEmailError: string;
    registerError: string;
    emailConfirmed: boolean;
    smsConfirmError: string;

    constructor(
        private modalService: ModalService,
        private apiService: ApiService,
        private loaderService: LoaderService,
        private readonly fb: FormBuilder
    ) {
        this.authForm = this.fb.group({
            phone: [{
                value: '',
                disabled: false
            }, [(control: AbstractControl) => {
                return {invalid: true};
            }]],
            formattedPhone: ['']
        });

        this.changeEmailForm = this.fb.group({
            email: ['', [Validators.email]]
        });

        this.registerForm = this.fb.group({
            email: ['', [Validators.email]],
            firstName: ['', [Validators.required]],
            middleName: [''],
            lastName: ['', [Validators.required]],
            password: ['', [Validators.required]],
            smsCode: ['', [Validators.required]]
        });

        this.confirmSMSForm = this.fb.group({
            smsCode: ['', [Validators.required]]
        });
    }

    cancel() {
        this.modalService
            .setResponse(false)
            .close();
    }

    async auth() {
        this.waiting = true;
        this.authError = null;
        try {
            const response = await this.apiService.simpleAuthCheck<SimpleAuthResponse>(`${this.authForm.value.phone}`);
            if (response.phoneConfirmed) {
                this.enterUserInfoState(response);
            } else {
                this.enterRegisterState();
            }
        } catch (e) {
            this.authError = e.error.errorMessage;
        }
        this.waiting = false;
    }

    private enterUserInfoState(response: SimpleAuthResponse) {
        this.state = 'USER_INFO';
        this.maskedEmail = response.email;
        this.emailConfirmed = response.emailConfirmed;
        this.authForm.controls.phone.disable();
    }

    private enterRegisterState() {
        this.state = 'REGISTER';
        this.authForm.controls.phone.disable();
    }

    public enterChangeEmailState() {
        this.state = 'CHANGE_EMAIL';
    }

    public enterConfirmSMSState() {
        this.state = 'SMS_CONFIRM';
    }

    public async changeEmail() {
        this.waiting = true;
        this.changeEmailError = null;
        try {
            const email = this.changeEmailForm.value.email;
            if (this.emailConfirmed) {
                await this.apiService.simpleEmailChange(`${this.authForm.controls.phone.value}`, email);
                this.enterConfirmSMSState();
            } else {
                await this.apiService.simpleAuth(`${this.authForm.controls.phone.value}`, email);
                this.authenticated();
            }
        } catch (e) {
            this.changeEmailError = e.error.errorMessage;
        }
        this.waiting = false;
    }

    public async skipEmailChange() {
        this.waiting = true;
        try {
            await this.apiService.simpleAuth(`${this.authForm.controls.phone.value}`);
            this.authenticated();
        } catch (e) {

        }
        this.waiting = false;
    }

    public async confirmSMS() {
        this.waiting = true;
        this.smsConfirmError = null;
        try {
            const email = this.changeEmailForm.value.email;
            const smsCode = this.confirmSMSForm.value.smsCode;
            await this.apiService.simpleAuth(`${this.authForm.controls.phone.value}`, email, smsCode);
            this.authenticated();
        } catch (e) {
            this.smsConfirmError = e.error.errorMessage;
        }
        this.waiting = false;
    }

    private authenticated() {
        this.modalService
            .setResponse(true)
            .close();
    }

    public async register() {
        const model = new HttpParams()
            .set('email', this.registerForm.value.email)
            .set('firstName', this.registerForm.value.firstName)
            .set('lastName', this.registerForm.value.lastName)
            .set('middleName', this.registerForm.value.middleName)
            .set('password', this.registerForm.value.password)
            .set('smsCode', this.registerForm.value.smsCode);

        this.waiting = true;
        this.registerError = null;
        try {
            await this.apiService.simpleReg(model);
            this.authenticated();
        } catch (e) {
            this.registerError = e.error.errorMessage;
        }
        this.waiting = false;
    }

    public onNumberChange(phoneNumberInfo: { phone: string, valid: boolean, formattedPhone: string }): void {
        this.authForm.controls.phone.setValue(phoneNumberInfo.phone);
        this.authForm.controls.formattedPhone.setValue(phoneNumberInfo.formattedPhone);
        if (!phoneNumberInfo.valid) {
            if (!this.authForm.controls.phone.invalid) {
                this.authForm.controls.phone.setErrors({invalid: true});
            }
        } else {
            this.authForm.controls.phone.setErrors(null);
        }
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScaleElementAnimation} from '@app/_core/animation/animations';
import {ModalService} from '@app/shared/modal-service/modal.service';
import {ApiService} from '@app/shared/api/api.service';
import {LoaderService} from '@app/content/components/loader/loader.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

interface Person {
    eMail: string;
    externalId: number;
    firstName: string;
    lastName: string;
    middleName: string;
    personId: number;
    phone: string;
}

interface PayCreditResponse {
    applicationLink: string;
}

@Component({
    selector: 'app-loan-application-modal',
    templateUrl: './loan-application-modal.component.html',
    styleUrls: ['./loan-application-modal.component.scss'],
    animations: [ScaleElementAnimation]
})
export class LoanApplicationModalComponent implements OnInit, OnDestroy {

    waiting = false;

    form: FormGroup;

    subscription: Subscription;

    orderId: number;

    constructor(
        private modalService: ModalService,
        private apiService: ApiService,
        private loaderService: LoaderService,
        private readonly fb: FormBuilder
    ) {
        this.form = this.fb.group({
            firstName: [{value: '', disabled: true}],
            middleName: [{value: '', disabled: true}],
            lastName: [{value: '', disabled: true}],
            phone: [{value: '', disabled: true}],
            email: [{value: '', disabled: true}]
        });
    }

    async ngOnInit(): Promise<void> {
        this.subscription = this.modalService.getData()
            .subscribe((data: { orderId: number }) => {
                this.orderId = data.orderId;
            });
        this.waiting = true;
        try {
            const person = await this.apiService.getPersonalInfo<Person>();
            this.form.patchValue({
                firstName: person.firstName,
                lastName: person.lastName,
                middleName: person.middleName,
                phone: person.phone,
                email: person.eMail
            });
        } catch (e) {

        }
        this.waiting = false;
    }

    cancel() {
        this.modalService
            .setResponse(false)
            .close();
    }

    async send() {
        this.waiting = true;
        try {
            const response = await this.apiService.orderPayCredit<PayCreditResponse>(this.orderId);
            window.location.href = response.applicationLink;
        } catch (e) {

        }
        this.waiting = false;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

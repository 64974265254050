import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  public isShowPopup = false;
  private config: any;
  createdAt = null;

  constructor() {
  }

  open(config = null) {
    if (environment.enablePreloader) {
      if (config) {
        this.config = config;
        this.createdAt = Date.now();
      }
      setTimeout(() => {
        this.isShowPopup = true;
      }, 0);
    }
  }

  close() {
    let hideTimeout = 0;
    if (this.config && this.config.min) {
      hideTimeout = Math.max(0, this.config.min - (Date.now() - this.createdAt));
    }
    window.setTimeout(() => {
      this.isShowPopup = false;
    }, hideTimeout);
  }
}

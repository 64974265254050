import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-smile-toggle',
  templateUrl: './smile-toggle.component.html',
  styleUrls: ['./smile-toggle.component.scss']
})
export class SmileToggleComponent implements OnInit {

  @Input() data: any;

  @Input() onChangeStatus: (data: any) => {};

  constructor() { }

  ngOnInit() {
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '@app/content/components/loader/loader.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private router: Router,
              private loaderService: LoaderService) {
  }

  ngOnInit() {
  }

  goToMainPage() {
    this.loaderService.open()
    this.router.navigate(
      ['/'],
      {queryParamsHandling: 'merge'}
    )
      .then(() => this.loaderService.close());
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitDate'
})
export class SplitDatePipe implements PipeTransform {
  transform(val: string, ...args): any {
    if (val) {
      const value = val.includes(' ') ? val.split(' ')[0] : val;
      const time = val.includes(' ') ? val.split(' ')[1] : '';
      const mass = value.split('.');
      [mass[0], mass[2]] = [mass[2], mass[0]];
      return new Date(mass.join('-'));
    } else {
      return '';
    }
  }
}

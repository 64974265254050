import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EventItemComponent} from './event-item.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {MomentModule} from 'ngx-moment';

@NgModule({
    declarations: [EventItemComponent],
    imports: [
        CommonModule,
        AngularSvgIconModule,
        MomentModule
    ],
    exports: [
        EventItemComponent
    ]
})
export class EventItemModule {
}
